import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import JobPreparation from '../../Components/JobPreparation';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'
import MarqueeComponent from '../../Components/MarqueeComponent'

const SucessFactors = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);


  const roadmapData = [
    {
      title: 'Introduction to SAP SuccessFactors',
      points: [
        'What is SAP SuccessFactors?',
        'Importance of SAP SuccessFactors in HR management.',
        'Overview of SAP HCM vs. SAP SuccessFactors.',
        'Benefits of cloud-based HR solutions.',
        'SAP SuccessFactors certification overview.',
        'SAP SuccessFactors jobs in India.',
        'SAP SuccessFactors jobs in Nagpur.',
      ],
    },
    {
      title: 'Stakeholder Engagement',
      points: [
        'Engage with key stakeholders, including HR, IT, and business leaders.',
        'Gather feedback on current HR processes and areas for improvement.',
      ],
    },
    {
      title: 'Cloud Migration Consideration',
      points: [
        'Assess the feasibility of adopting SAP SuccessFactors for cloud-based HR management.',
        'Explore benefits of scalability, accessibility, and real-time insights.',
      ],
    },
    {
      title: 'Data Security and Compliance',
      points: [
        'Implement robust security measures to protect sensitive HR data.',
        'Ensure compliance with data protection regulations like GDPR and local laws.',
      ],
    },
    {
      title: 'Analytics and Reporting',
      points: [
        'Enhance reporting capabilities for advanced HR analytics.',
        'Leverage predictive analytics for workforce planning and decision-making.',
      ],
    },
    {
      title: 'User Experience (UX) Improvements',
      points: [
        'Enhance the overall user experience within SAP SuccessFactors modules.',
        'Optimize interfaces for HR, employees, and managers for ease of use.',
      ],
    },
    {
      title: 'Post-Implementation Support',
      points: [
        'Develop a robust support and maintenance plan for SAP SuccessFactors.',
        'Establish a dedicated team to address issues and ensure ongoing support.',
      ],
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include relevant keywords like SAP SuccessFactors and HR analytics.',
        'How to create a professional and impactful resume.',
        'Tips for freshers on building an HR-focused resume.',
        'Showcase measurable achievements and impact.',
        'Highlight technical and soft skills.',
        'Be unique and align your resume with the job description.',
      ],
    },
  ];
  
  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },  
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];
  const syllabus = [
    {
      title: 'SAP SuccessFactors - Introduction',
      subpoints: [
        'What is SAP SuccessFactors?',
        'SAP SuccessFactors Cloud Overview',
        'Core HR and Talent Management Overview',
        'Introduction to Employee Central',
        'SuccessFactors Architecture and Features',
        'Integration of SuccessFactors with SAP HCM and S/4 HANA',
        'Navigation and System Landscape in SuccessFactors',
      ],
    },
    {
      title: 'Employee Central - Core HR',
      subpoints: [
        'Employee Data Management',
        'Foundation Objects and Data Models',
        'Position Management',
        'Event Reason Derivation',
        'Workflow and Approvals',
        'Business Rules Configuration',
        'Global Assignments and Concurrent Employment',
      ],
    },
    {
      title: 'SuccessFactors Recruiting',
      subpoints: [
        'Recruitment Marketing and Management',
        'Job Requisition Templates and Configuration',
        'Candidate Profile Setup and Talent Pools',
        'Application Tracking and Screening',
        'Interview and Offer Management',
      ],
    },
    {
      title: 'Learning Management System (LMS)',
      subpoints: [
        'Overview of SuccessFactors LMS',
        'Course Setup and Content Management',
        'Assignment Profiles and User Groups',
        'Online and Classroom Training',
        'Evaluations and Learning Analytics',
      ],
    },
    {
      title: 'Performance and Goal Management',
      subpoints: [
        'Creating and Managing Goal Plans',
        'Performance Review Templates',
        'Calibration and Employee Assessment',
        'Continuous Performance Management',
        'Integration with Compensation Management',
      ],
    },
    {
      title: 'Compensation and Variable Pay',
      subpoints: [
        'Compensation Plan Setup',
        'Budget Allocation and Guidelines',
        'Bonus and Incentive Management',
        'Pay Matrix Configuration',
        'Compensation Statements',
      ],
    },
    {
      title: 'SuccessFactors Analytics and Reporting',
      subpoints: [
        'Overview of People Analytics',
        'Building Ad Hoc Reports',
        'Story Reporting in SuccessFactors',
        'Dashboards and Insights',
        'Predictive Analytics for Workforce Planning',
      ],
    },
    {
      title: 'Time Tracking and Absence Management',
      subpoints: [
        'Time Off Configuration and Policies',
        'Time Sheet Setup',
        'Overtime and Leave Management',
        'Holiday Calendar and Work Schedules',
      ],
    },
    {
      title: 'Integration and Security',
      subpoints: [
        'Integration Center Overview',
        'SAP CPI for SuccessFactors Integration',
        'User Roles and Permissions',
        'Data Privacy and Security Compliance',
      ],
    },
    {
      title: 'In Addition',
      subpoints: [
        '5+ Real-Time Projects',
        '20+ Topic-Wise Tests',
        'Resume Building and Job Assistance',
        'Interview Preparation and Mock Interviews',
        '6-Month Internship with Industry Experience',
        '100% Job Opportunities',
        'One-on-One Classroom and Online Training',
      ],
    },
  ];
  
  const keyFeatures = [
    "162+ Hours of Course Duration",
    "100% Job-Oriented Training",
    "Led by Industry Expert Faculties",
    "Hands-On Learning with Real-Time Projects",
    "Free Demo Classes Available",
    "Completed 200+ Batches Successfully",
    "Comprehensive Certification Guidance",
    "Access to Recorded Sessions and Study Materials",
    "Resume Building and Interview Preparation Support",
  ];
  

  const faqs = [
    {
      question: "What is SAP SuccessFactors?",
      answer: "SAP SuccessFactors is a cloud-based Human Experience Management (HXM) suite that helps organizations manage various HR processes, including recruitment, onboarding, performance management, and learning.",
    },
    {
      question: "How does SAP SuccessFactors support employee lifecycle management?",
      answer: "SAP SuccessFactors provides tools to manage the entire employee lifecycle, from recruitment and onboarding to performance evaluations, learning, and career development.",
    },
    {
      question: "What are the key modules in SAP SuccessFactors?",
      answer: "Key modules include Employee Central, Recruiting, Onboarding, Learning Management, Performance & Goals, Compensation, and Succession & Development.",
    },
    {
      question: "What is Employee Central in SAP SuccessFactors?",
      answer: "Employee Central is a core HR module that acts as a centralized repository for employee data and provides workflows for HR tasks like hiring, promotions, and transfers.",
    },
    {
      question: "How does SAP SuccessFactors improve talent management?",
      answer: "SAP SuccessFactors offers advanced tools for recruiting, performance evaluation, goal setting, and succession planning, enabling organizations to attract, retain, and develop top talent.",
    },
    {
      question: "Can SAP SuccessFactors integrate with other systems?",
      answer: "Yes, SAP SuccessFactors integrates with other SAP modules and third-party applications, ensuring seamless data flow and unified HR management across systems.",
    },
    {
      question: "How does SAP SuccessFactors support learning and development?",
      answer: "The Learning Management module in SAP SuccessFactors helps organizations deliver, track, and manage employee training programs, enhancing skill development and compliance.",
    },
    {
      question: "Is SAP SuccessFactors customizable for specific business needs?",
      answer: "Yes, SAP SuccessFactors offers flexible configuration options, allowing organizations to tailor the system to their specific HR processes and business requirements.",
    },
    {
      question: "What is the reporting capability in SAP SuccessFactors?",
      answer: "SAP SuccessFactors provides robust analytics and reporting tools that enable organizations to gain insights into workforce performance and make data-driven decisions.",
    },
    {
      question: "What are the benefits of using SAP SuccessFactors?",
      answer: "SAP SuccessFactors offers a user-friendly interface, cloud-based accessibility, scalability, and powerful tools for managing HR processes efficiently, leading to enhanced employee engagement and organizational success.",
    },
  ];
  

  const whatsData = [
    {
      title: 'What is SAP SuccessFactors?',
      points: [
        'SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR processes effectively.',
        'It covers key areas such as Employee Central, Talent Management, Learning Management, and Workforce Analytics.',
        'SAP SuccessFactors facilitates seamless employee lifecycle management, including hiring, onboarding, and performance reviews.',
        'The platform supports talent acquisition, enabling organizations to attract, recruit, and retain top talent.',
        'Learning management features help in planning, delivering, and tracking employee training and development programs.',
        'Workforce analytics provides real-time insights to improve decision-making and strategic planning.',
        'SAP SuccessFactors ensures scalability, accessibility, and integration with other SAP solutions like S/4 HANA.',
        'IT Accurate offers comprehensive SAP SuccessFactors training, including end-user, consulting, implementation, and support training.',
        'Training programs cater to individuals from IT and non-IT backgrounds, freshers, and experienced professionals.',
        'IT Accurate is the best training institute in Nagpur, providing real-time project-based training with 100% job placement assistance.',
      ],
    },
  ];
  

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
  <h1>Sapalogy - Best SAP SuccessFactors Training in Nagpur</h1>
  <p>
    Sapalogy offers you the best SAP SuccessFactors training in Nagpur for both freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our comprehensive training course covers every technical topic in detail, ensuring students gain real-time hands-on experience and practical knowledge.
  </p>
  <br />
  <p>
    Our practical, job-oriented training program provides not only a globally recognized certificate but also knowledge equivalent to around 2+ years of industry experience. We value your time, which is why our specialized program is designed to train you in SAP SuccessFactors with S/4 HANA integration in just 4 months.
  </p>

  <h3>All topics will be covered in detail and also include:</h3>
  <ul className="points-list">
    <li><FontAwesomeIcon icon={faFileAlt} /> End user training (ECC / S4 HANA) – 1 month</li>
    <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration (ECC / S4 HANA) – 2 months</li>
    <li><FontAwesomeIcon icon={faBriefcase} /> Project – 1 month</li>
    <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
    <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
    <li><FontAwesomeIcon icon={faBriefcase} /> 6-month internship</li>
    <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
  </ul>
</div>


        <PageForm />
      </div>
    
{/* 
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div> */}
      <Roadmap heading="Roadmap to learn SAP SuccessFactors " roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP SuccessFactors Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP SuccessFactors Training" keyFeatures={keyFeatures} />

   

        <BatchesPage Batches={BatchesPage} />

<JobPreparation/>



      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP SuccessFactors  CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
          <ul>
  <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
  <li>The SAP SuccessFactors certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
  <li>Sapalogy provides comprehensive guidance for your SAP SuccessFactors global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP SuccessFactors Platform Development Certification, and various other global exams.</li>
</ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>


      {/* <Roadmap heading="SAP modules" roadmapData={modulesData} /> */}
      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP HCM Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />
{/* 
      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

<CustomMarquee/>
 
      <CourseOpportunities pageName="SuccessFactors" />
      <MarqueeComponent />	
      <Contact
  className=""
  style={{
    position: "relative",
    left: "50px",

  }}
/>

    </div>
  );
};

export default SucessFactors;