import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../Css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Import social icons
import '../Css/Footer.css';
import logo from '../assets/Logo.webp'; 

function Footer() { 
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [activeSubAccordion, setActiveSubAccordion] = useState(null); 
    const accordionRef = useRef(null);

    
    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
        if (activeAccordion !== index) setActiveSubAccordion(null); 
    };

    const toggleSubAccordion = (index) => {
        setActiveSubAccordion(activeSubAccordion === index ? null : index);
    };

    // Close accordion if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (accordionRef.current && !accordionRef.current.contains(event.target)) {
                setActiveAccordion(null);
                setActiveSubAccordion(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-links" ref={accordionRef}>
                    <div className="footer-logo">
                        <img src={logo} alt="Company Logo" />
                        <h3> Sapalogy Training </h3>

                    </div>


                    <div className="footer-section">
                        <h4>Quick Links</h4>

                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><div className={`accordion-item ${activeAccordion === 0 ? 'active' : ''}`}>
                                <button onClick={() => toggleAccordion(0)} className="accordion-toggle">
                                    Courses <span>{activeAccordion === 0 ? '▲' : '▼'}</span>
                                </button>
                                <div className={`accordion-content ${activeAccordion === 0 ? 'active' : ''}`}>
                                    <div className={`accordion-item ${activeSubAccordion === 1 ? 'active' : ''}`}>
                                        <button onClick={() => toggleSubAccordion(1)} className="accordion-toggle">
                                            <a href="/salesforce-training" >Salesforce</a> <span>{activeSubAccordion === 1 ? '▲' : '▼'}</span>
                                        </button>
                                        <div className={`accordion-content ${activeSubAccordion === 1 ? 'active' : ''}`}>
                                            <ul>
                                                <li><a href="/salesforce-admin">Salesforce Admin</a></li>
                                                <li><a href="/salesforce-development-2">Salesforce Development</a></li>
                                                <li><a href="/salesforce-lwc-integration">Salesforce LWC & Integration</a></li>
                                                <li><a href="/salesforce-marketing-cloud">Salesforce Marketing Cloud</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><a href="/best-data-analytics-training">Data Analytics</a></li>
                                        <li><a href="/best-data-science-training">Data Science</a></li>
                                        <li><a href='/best-devops-training'>DevOps</a></li>
                                        <li><a href="/best-aws-training">AWS</a></li>
                                        <li><a href="/best-business-analytics-training">Business Analytics</a></li>
                                        <li><a href="/best-ai-ml-training">AI & ML</a></li>
                                        <li><a href="/best-machine-learning-training">Machine Learning</a></li>
                                        <li><a href="/tally">Tally</a></li>
                                        <li><a href="/full-stack-developer">Full Stack Development</a></li>
                                        <li><a href="/python">Python</a></li>
                                        <li><a href="/share-market">Share Market</a></li>
                                    </ul>
                                </div>
                            </div></li>
                            <li><a href="/about-us">About</a></li>
                            <li><a href="/b">Blogs</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h4>Legal</h4>
                        <ul>
                            <li><a href="/terms">Terms of Service</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                            <li><a href="/refund">Refund policy</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h4>Contact</h4>
                        <ul>
                            <li>Email: <a href="mailto:info@sapalogytraining.com">info@sapalogytraining.com</a></li>
                            <li><a href="tel:09175978889">Phone: 09175978889</a></li>
                            <li><a href="https://www.google.com/maps/dir//607,+608+B-wing,+Lokmat+Bhavan,+Lokmat+Square,+Ramdaspeth,+Nagpur,+Maharashtra+440012/@21.1354607,78.9956269,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bd4bf33b07e2643:0x1b45df16c0e6fa96!2m2!1d79.0780286!2d21.1354806?entry=ttu" alt='map-link'>
                                Address: 607, 608 B-wing, Lokmat Bhavan, Lokmat Square, Ramdaspeth, Nagpur, Maharashtra 440012
                            </a></li>
                        </ul>
                    </div>

                    <div className="social-icons">
                        <a href="https://www.instagram.com/sapalogy_pvt.ltd/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.facebook.com/Sapalogy/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://www.youtube.com/channel/UCXZYBXNxKE02guTUKujOq7w" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href="https://www.linkedin.com/posts/sapalogy-pvt-ltd_sapeducation-partner-nasik-activity-6584811124691165184-5l6o/?trk=public_profile_like_view" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
