import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
// project slider image
import dev1 from '../../assets/Projects/Salesforce/1.png';
import dev2 from '../../assets/Projects/Salesforce/2.png';
import dev3 from '../../assets/Projects/Salesforce/3.png';
import dev4 from '../../assets/Projects/Salesforce/4.png';
import dev5 from '../../assets/Projects/Salesforce/5.png';
import dev6 from '../../assets/Projects/Salesforce/6.png';
import dev7 from '../../assets/Projects/Salesforce/7.png';
import dev8 from '../../assets/Projects/Salesforce/8.png';
import dev9 from '../../assets/Projects/Salesforce/9.png';
import dev10 from '../../assets/Projects/Salesforce/10.png';

import rev1 from '../../assets/Reviews/Salesforce/sf_rev1.png';
import rev2 from '../../assets/Reviews/Salesforce/sf_rev2.png';
import rev3 from '../../assets/Reviews/Salesforce/sf_rev3.png';
import rev4 from '../../assets/Reviews/Salesforce/sf_rev4.png';
import rev5 from '../../assets/Reviews/Salesforce/sf_rev5.png';
import RegularSlider from '../../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle,faStar } from '@fortawesome/free-solid-svg-icons';
import JobPreparation from '../../Components/JobPreparation';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'

import MarqueeComponent from '../../Components/MarqueeComponent'
const SalesforceLWC = () => {





  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'Salesforce LWC Development',
        'What is Salesforce LWC?',
        'Salesforce LWC Careers',
        'Salesforce LWC course',
        'Salesforce LWC certification',
        'Salesforce LWC job in india',
        'Salesforce LWC job in nagpur'
      ]
    },
    {
      title: 'Understanding Salesforce Fundamentals',
      points: [
        'Salesforce login',
        'Salesforce trailhead login',
        'All the topics of salesforce admin are covered in detail',
      ]
    },
    {
      title: 'Salesforce Development',
      points: [
        'Salesforce developer login',
        'Basic of C Language',
        'APEX Programming',
        'All the topics of salesforce development are covered in detail',
      ]
    },
    {
      title: 'Salesforce LWC and Integration',
      points: [
        'What is LWC?',
        'Basic HTML',
        'JAVA SCRIPT',
        'All the topics of salesforce Lwc and Integration are covered in detail.'
      ]
    },
    {
      title: 'Salesforce Einstein Bots',
      points: [
        'Building a NLP-Powered chatbot',
        'Dialog flows using no-coding configuration',
        'Out of the box functions using APX class',
        'Deploy your Bot to website'
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keyword',
        'Show impact',
        'Include soft skill',
        'Be unique'
      ]
    },
    {
      title: 'Interview Practice',
      points: [
        'How to do face to face online & offline interview',
        'One of one interview sessions',
        'Group discussion',
        'Grooming session',
        'Research the company and follow ups'
      ]
    },
    {
      title: 'JOB opportunities',
      points: [
        'Preapare for your JOB search',
        'Ready Walkin in India',
        'Tie Up refernces',
        'GET PLACED',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction To HTML',
      subpoints: [
        'Basic Of HTML.',
        'HTML Tags',
        'HTML Attributes',
        'Inline CSS',
      ],
    },
    {
      title: 'Introduction To JavaScript',
      subpoints: [
        'DataTypes In Java Script',
        'Interaction with alert, prompt and confirm',
        'Variables In JS',
        'Function and Methods In JS',
        'Object In JS',
      ],
    },
    {
      title: 'Introduction To Lightening Web Components',
      subpoints: [
        'Base Componenets In LWC',
        'Lightning-Card',
        'Lightning-Input',
        'Lightning-Button',
        'Lightning Record Edit Form',
        'Lightning View Form',
        'Lightning Input Form',
        'Lightning Output Form',
      ],
    },
    {
      title: 'Decorators in LWC',
      subpoints: [
        'Track',
        'API',
        'WIRE',
      ],
    },
    {
      title: 'Life Cycle Hooks In LWC',
      subpoints: [
        'Constructors',
        'Connected Call Back',
        'Disconnected Call Back',
        'Rendered Call Back',
        'Error Call Back',
      ],
    },
    {
      title: 'Compositions In LWC',
      subpoints: [
        'Parent to Child Communication',
        'Child to Parent Communication',
        'Getter and Setter Methods',
      ],
    },
    {
      title: 'Introduction To Lightning Design System',
      subpoints: [
        'How to Used lds in LWC',
        'LDS Components',
      ],
    },
    {
      title: 'Introduction To Integration',
      subpoints: [
        'Exeptions',
        'Exception Statements',
        'System-Defined Exception',
        'Catching Different Exception Types',
        'Try Catch and Finally Methods',
      ],
    },
    {
      title: 'APEX Testing Essentials',
      subpoints: [
        'Write and run APEX Tests',
        'Create Tests Data',
        'Running Test Units',
        'Testing Best Practices',
        'Code Coverage',
        'Test Classes Methods',
      ],
    },

  ];

  const keyFeatures = [
    "34+ Hrs Instructor Led Training",
    "Certificate guidance",
    "Mentor support",
    "Resume editing",
    "10+ Hrs Project & Exercises",
    "100% Job oriented Training",
    "56+ Hrs Self-placed videos",
    "Job Assistance",
    "Free demo class available",
    "Affordable fees structure",
    "Flexible Schedule",
    "Completed 420+ batches",
  ];

  const faqs = [
    {
      question: "What is Salesforce LWC?",
      answer: "LWC is a modern UI framework within Salesforce for building responsive web applications using JavaScript and HTML.",
    },
    {
      question: "How do I start learning Salesforce LWC?",
      answer: "Begin by understanding LWC basics like component structure, JavaScript controllers, and HTML templates, then practice building simple components.",
    },
    {
      question: "What is Integration in Salesforce?",
      answer: "Integration in Salesforce refers to connecting with external systems such as databases and third-party applications for data exchange and synchronization.",
    },
    {
      question: "What are Salesforce APIs used for Integration?",
      answer: "Salesforce APIs like REST and SOAP are used for seamless integration with external systems, enabling data retrieval, updates, and real-time communication.",
    },
    {
      question: "How do I integrate Salesforce with external systems?",
      answer: "Integration with external systems involves using Salesforce APIs, setting up authentication, mapping data fields, and implementing integration patterns.",
    },
    {
      question: "What are the benefits of using Salesforce LWC for UI development?",
      answer: "LWC offers benefits like improved performance, code reusability, responsive design, and seamless integration with Salesforce features.",
    },
    {
      question: "What integration patterns are commonly used in Salesforce?",
      answer: "Common integration patterns in Salesforce include point-to-point, publish-subscribe, batch data synchronization, and real-time event-driven integration.",
    },
    {
      question: "How can I ensure data security in Salesforce Integration?",
      answer: "Ensure data security in Salesforce Integration by implementing secure authentication methods, setting up access controls, and encrypting sensitive data during transmission.",
    },
  ];

  const whatsData = [
    {
      title: 'What is Salesforce LWC & INTEGRATION ??',
      points: [
        'Modern UI Framework: LWC is a modern UI framework within Salesforce, facilitating fast development and enhanced user experiences.',
        'External System Connectivity: Integration with external systems such as databases and third-party applications is seamless, ensuring data exchange and synchronization.',
        'Improved Performance: LWC’s use of modern web standards and APIs like REST and SOAP boosts performance and enables real-time data updates across platforms.',
        'IT background, non IT background, Freshers, Experience can start their career in salesfore Development irrrespective of their background.',
        'Start by understanding the basics of platform, get training with sapalogy and Join user group, Communities, and placement oriented training of Salesforce LWC Development will give you hand experience.',
        'Becoming a Salesforce pro is achievable through unwavering hard work, dedication, and commitment.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
    { Image: dev9, alt: "Award 7" },
    { Image: dev10, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Salesforce LWC &
            Integration Training in Nagpur</h1>
            <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">6489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6667 Learners</span>
          </div>
          <p>

            Salesforce Training by Sapalogy offers you  the Best Salesforce Training in Nagpur for Fresher and Experience candidate in Nagpur, with Expert Guidance and 100% Guaranteed Placement Assistance. Our  Training Course cover  every technical topics in details and the student is left with Real-Time Hands-On Experience.
          </p>
          <br />
          <p>
            Our practical, Job-Oriented Training Program, will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of Field Experience. We value your time as much as ours. Hence we intend to train you in Salesforce in just 1 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><i className="fas fa-file-alt"></i> *Resume preparation</li>
            <li><i className="fas fa-chalkboard-teacher"></i> *Interview practice</li>
            <li><i className="fas fa-briefcase"></i> *6-months internship</li>
            <li><i className="fas fa-check-circle"></i> *100% job opportunities guaranteed program</li>
          </ul>
        </div>
        <PageForm />
      </div>

     
      <div className="video-section">
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/syzh1IxEaak?rel=0&modestbranding=1&loop=1&playlist=syzh1IxEaak"
          title="DevOps Video"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="responsive-iframe"
        ></iframe>
      </div>
    </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn Salesforce LWC & INTEGRATION" roadmapData={roadmapData} />
      <Syllabus heading="Salesforce LWC & INTEGRATION Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Salesforce LWC & INTEGRATION" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for Salesforce LWC Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the Salesforce LWC & INTEGRATION Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Salesforce LWC</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
           
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div></div>



      <section className="devops-certification-section">
        <h2 className="certification-heading">Salesforce LWC Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The Salesforce LWC certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your Salesforce LWC global certification, ensuring a 100% passing guarantee in examinations.</li>
              <li>Salesforce LWC Platform Development Certification and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <BatchesPage Batches={BatchesPage} />
<JobPreparation/>

      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">Salesforce LWC & INTEGRATION</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>

      <CustomMarquee/>
      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>


    
      <CourseOpportunities pageName="SalesforceLWC" />
   
  <MarqueeComponent />	
      <Contact />

    </div>
  );
};

export default SalesforceLWC;