import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { X, ChevronDown, ChevronRight, AlignRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

export default function MobMenu({ Menus }) {
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState(null); // Track which parent menu is clicked
  const [activeMiniSubMenu, setActiveMiniSubMenu] = useState(null); // Track active mini sub menu
  const location = useLocation(); // Track route location

  // Toggle drawer (mobile menu)
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setClicked(null);
    setActiveMiniSubMenu(null);
  };

  // Submenu animation for opening and closing
  const subMenuDrawer = {
    enter: { height: "auto", overflow: "hidden" },
    exit: { height: 0, overflow: "hidden" },
  };

  // Mini submenu animation for opening and closing
  const miniSubMenuDrawer = {
    enter: {
      x: 0,
      opacity: 1,
      height: "auto",
      overflow: "hidden",
      transition: { duration: 0.3 },
    },
    exit: {
      x: -10,
      opacity: 0,
      height: 0,
      overflow: "hidden",
      transition: { duration: 0.3 },
    },
  };

  // Close the menu when the route changes
  useEffect(() => {
    if (isOpen) {
      setIsOpen(false); // Close menu on route change
    }
  }, [location]);

  return (
    <div>
      <button
        className="lg:hidden mt-4 z-[999] relative"
        onClick={toggleDrawer}
      >
        {isOpen ? <X /> : <AlignRight />}
      </button>

      <motion.div
        className="fixed left-0 right-0 top-16 overflow-y-auto h-screen w-full bg-black backdrop-blur text-white p-6 pb-20"
        initial={{ x: "-100%" }}
        animate={{ x: isOpen ? "0%" : "-100%" }}
      >
        <ul>
          {Menus.map(({ name, subMenu, path }, i) => {
            const isClicked = clicked === i;
            const hasSubMenu = subMenu?.length;

            return (
              <li key={name}>
                <span
                  className="flex justify-between items-center p-4 hover:bg-black/5 rounded-md cursor-pointer"
                  onClick={() => {
                    setClicked(isClicked ? null : i); // Toggle visibility of submenu
                    setActiveMiniSubMenu(null); // Reset mini submenu when switching menus
                  }}
                >
                  {path ? (
                    <Link to={path} className="flex items-center gap-1">
                      {name}
                      {hasSubMenu && (
                        <ChevronDown
                          className={`ml-auto ${isClicked && "rotate-180"}`}
                        />
                      )}
                    </Link>
                  ) : (
                    <>
                      {name}
                      {hasSubMenu && (
                        <ChevronDown
                          className={`ml-auto ${isClicked && "rotate-180"}`}
                        />
                      )}
                    </>
                  )}
                </span>

                {hasSubMenu && (
                  <motion.ul
                    initial="exit"
                    animate={isClicked ? "enter" : "exit"}
                    variants={subMenuDrawer}
                    className="ml-5 pl-2 border-l border-gray-200"
                  >
                    {subMenu.map(
                      (
                        { name, icon: Icon, minisubMenus, path, logoUrl },
                        j
                      ) => (
                        <li key={name}>
                          <div
                            className="flex items-center justify-between p-2 hover:bg-black/5 rounded-md cursor-pointer gap-x-2"
                            onClick={() => {
                              // Toggle the mini sub menu
                              setActiveMiniSubMenu(
                                activeMiniSubMenu === j ? null : j
                              );
                            }}
                          >
                            <div className="flex items-center gap-x-2">
                              {/* Render logo if logoUrl is provided, otherwise render icon */}
                              {logoUrl ? (
                                <img
                                  src={logoUrl}
                                  alt={name}
                                  className="w-5 h-5 object-contain rounded-full"
                                />
                              ) : (
                                Icon && <Icon size={17} />
                              )}
                              {path ? (
                                <Link to={path}>{name}</Link> // Add Link for submenu
                              ) : (
                                name
                              )}
                            </div>
                            {minisubMenus &&
                              minisubMenus.length > 0 && ( // Show Chevron only if mini submenus exist
                                <ChevronRight
                                  className={`ml-auto ${
                                    activeMiniSubMenu === j && "rotate-90"
                                  }`}
                                />
                              )}
                          </div>

                          {minisubMenus && activeMiniSubMenu === j && (
                            <motion.ul
                              initial="exit"
                              animate="enter"
                              exit="exit"
                              variants={miniSubMenuDrawer}
                              className="ml-6 pl-2 border-l border-gray-200"
                            >
                              {minisubMenus.map((minisubMenu, k) => (
                                <li
                                  key={k}
                                  className="p-2 pl-4 hover:bg-black/10 rounded-md cursor-pointer text-sm"
                                >
                                  {minisubMenu.path ? (
                                    <Link to={minisubMenu.path}>
                                      {minisubMenu.name}
                                    </Link>
                                  ) : (
                                    minisubMenu.name
                                  )}
                                </li>
                              ))}
                            </motion.ul>
                          )}
                        </li>
                      )
                    )}
                  </motion.ul>
                )}
              </li>
            );
          })}
        </ul>
      </motion.div>
    </div>
  );
}
