import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6',marginTop:'4rem' }}>
      <h1>Privacy Policy</h1>
      <p>
        At Sapalogy Training, we are committed to ensuring your personal information is protected at all times, and this privacy policy outlines what data Sapalogy Training collects, uses, and processes about you. It also explains your data rights and how you can contact us if you have any queries. We hold the right to alter this privacy policy statement at our discretion and encourage you to check this page from time to time to be aware of any changes.
      </p>

      <h2>To whom does this policy apply?</h2>
      <p>
        This privacy policy statement applies to all the individuals who interact with our website, subscribe to any of our services or products, request information, or use chat support.
      </p>

      <h2>What data do we collect?</h2>
      <h3>Parts of the Services</h3>
      <p>
        Let you interact with other users or share content publicly, including by posting reviews about content, asking or answering questions, sending messages to students, or posting photos or other work you upload. Such shared content may be publicly viewable by others depending on where it is posted.
      </p>

      <h3>Educational Content Data</h3>
      <p>
        When you access content, we collect certain data including which courses, assignments, practice tests, and quizzes you’ve started and completed; content purchases and credits; your exchanges with instructors, teaching assistants, and other students; and essays, answers to questions, and other items submitted to satisfy course and related content requirements.
      </p>

      <h3>Student Payment Data</h3>
      <p>
        If you make purchases, we collect certain data about your purchase (such as your name, billing address, and ZIP code) as necessary to process your order and which may optionally be saved to process future orders. You must provide certain payment and billing data directly to our payment service providers, including your name, credit card information, billing address, and ZIP code. We may also receive limited information, like the fact that you have a new card and the last four digits of that card, from payment service providers to facilitate payments. For security, Sapalogy Training does not collect or store sensitive cardholder data, such as full credit card numbers or card authentication data.
      </p>

      <h2>How do we collect data?</h2>
      <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
        <li>
          <strong>When you register to use our website:</strong> This includes your name, email address, city, and phone number. We may ask you to provide additional information about your business and preferences when you fill out online forms, use chat support, or download newsletters or other publications.
        </li>
        <li>
          <strong>When you register for a batch:</strong> This includes your name, address, email ID, and phone number and we may ask for your educational institution details.
        </li>
        <li>
          <strong>Cookies:</strong> Cookies are small files placed on your device when you first visit our website. They help analyze web traffic and track you across the internet. This data is collected based on “implied consent,” i.e., we assume that you agree to our terms of usage unless you explicitly opt-out. You can do so by changing your browser settings not to accept cookies or browsing in private mode.
        </li>
        <li>
          <strong>Google Analytics:</strong> It collects data related to the device/browser, IP address, and your activities on the website to measure and report statistics about your interactions on the web. It is used to enhance the user experience and track data related to digital marketing.
        </li>
        <li>
          <strong>Zoho:</strong> We use Zoho to anonymously track usage of our website, including your cursor hovers, clicks, scroll depth, and more. The mails we send are monitored to find out the traffic they bring, the click rates, and whether the mail is opened by the recipient to improve engagement.
        </li>
      </ul>

      <h2>Why do we collect data?</h2>
      <p>
        The data we collect can be personally identifiable or anonymous. We collect this data to/for:
      </p>
      <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
        <li>Offer personalized service</li>
        <li>Create a secure platform</li>
        <li>Improve performance and user experience</li>
        <li>Statistical Analysis</li>
        <li>Marketing Purposes</li>
        <li>Internal Research</li>
        <li>Fraud Detection</li>
      </ul>

      <h2>How do we secure your data?</h2>
      <p>
        The protection of your data is of great importance to us; hence we maintain appropriate technical and organizational measures to protect your data against any unauthorized or unlawful processing, accidental loss, alteration, disclosure, or accidental/unlawful destruction/damage.
      </p>
      <p>
        In case we discover a data breach, we will immediately inform the concerned authorities and affected users.
      </p>

      <h2>What are your rights?</h2>
      <p>
        You have the right to access your data, as well as request rectification or removal of your data. Furthermore, you have the right to object to the processing of your data. You can opt out of the mailing list by unsubscribing. You can contact us at <a href="mailto:admin@sapalogytraining.com">admin@sapalogytraining.com</a> to request your personal information or if you have any questions regarding our privacy policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
