import React, { useEffect, useRef, useState } from "react";
import "../../Css/DevOps.css";
import certificateImg from "../../assets/certificate.webp";
import Contact from "../Contact";
import IMG from "../../assets/Work.webp";
import Roadmap from "../../Components/Roadmap";
import WhatsDevOps from "../../Components/WhatsDevOps";
import PageForm from "../../Components/PageForm";
import Syllabus from "../../Components/Syllabus";
import KeyFeatures from "../../Components/KeyFeatures";
import Faq from "../../Components/Faq";

import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// project slider image
import dev1 from "../../assets/Projects/Salesforce/1.png";
import dev2 from "../../assets/Projects/Salesforce/2.png";
import dev3 from "../../assets/Projects/Salesforce/3.png";
import dev4 from "../../assets/Projects/Salesforce/4.png";
import dev5 from "../../assets/Projects/Salesforce/5.png";
import dev6 from "../../assets/Projects/Salesforce/6.png";
import dev7 from "../../assets/Projects/Salesforce/7.png";
import dev8 from "../../assets/Projects/Salesforce/8.png";
import dev9 from "../../assets/Projects/Salesforce/9.png";
import dev10 from "../../assets/Projects/Salesforce/10.png";

import rev1 from "../../assets/Reviews/Salesforce/sf_rev1.png";
import rev2 from "../../assets/Reviews/Salesforce/sf_rev2.png";
import rev3 from "../../assets/Reviews/Salesforce/sf_rev3.png";
import rev4 from "../../assets/Reviews/Salesforce/sf_rev4.png";
import rev5 from "../../assets/Reviews/Salesforce/sf_rev5.png";
import RegularSlider from "../../Components/RegularSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
  faProjectDiagram,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";
import JobPreparation from "../../Components/JobPreparation";
import CustomMarquee from "../../Components/CustomMarquee";
import CourseOpportunities from "../../Components/CourseOpportunities";
import BatchesPage from "../../Components/Batches";
import MarqueeComponent from "../../Components/MarqueeComponent";

const DAHR = () => {
  const roadmapData = [
    {
      title: "Introduction to HR Analytics",
      points: [
        "Definition and Importance",
        "Historical Evolution and Trends",
        "HR Metrics vs. HR Analytics",
        "Lead indicators and Lag indicators",
        "Data types and sources",
      ],
    },
    {
      title: "HR Data Management",
      points: [
        "Data Collection and Sources",
        "Internal HR Data",
        "External Data Sources",
        "Data Quality and Integrity",
      ],
    },
    {
      title: "Descriptive Analytics",
      points: [
        "Data Visualization tools (Excel, Tableau)",
        "Various types of Graphs",
        "Dashboards",
        "Statistical Analysis",
        "Measures of Central Tendency",
        "Measures of Dispersion",
        "Range",
        "Variance and Standard Deviation",
        "Correlation",
        "Examples of HR Metrics",
      ],
    },
    {
      title: "Microsoft Excel",
      points: [
        "Workbooks and Worksheets",
        "Ribbon",
        "Menu Items",
        "Quick Access Toolbar",
        "Formula Bar",
        "Status Bar",
        "Basic Navigation",
        "Basic operations",
        "Basic Functions (e.g., Sum, Average, Count, Counta, etc.)",
        "Relative and absolute references",
        "Logical functions (IF, AND, OR)",
        "Conditional Summing",
        "Conditional counting",
        "Lookups (vlookup, hlookup, xlookup)",
        "Text and number formatting",
        "Conditional formatting",
        "Data Validation",
        "Sorting and Filtering",
        "Advanced filtering",
        "Working with tables",
        "Statistical functions (Mean, Mode, Median)",
        "Text functions (Left, Right, Mid, Concat, Concatenate, Find, Search, etc.)",
        "Date functions (Today, Now, Date, Time, Datedif)",
        "Pivot tables",
        "Making graphs, inferences, and recommendations",
      ],
    },
    {
      title: "Working on Tableau",
      points: [
        "Introduction and Installation of the software",
        "Understanding Tableau Interface",
        "Connecting MS Excel to Tableau",
        "Understanding Measures and Dimensions",
        "Types of Graphs/Charts",
        "Creating visualizations",
        "Formatting options",
        "Applying filters",
        "Building Dashboards",
        "Publishing dashboards",
        "Downloading and sharing dashboards",
        "Creating Calculated Fields",
        "Creating combo charts",
      ],
    },
    {
      title: "Predictive Analytics",
      points: [
        "Concept of predictive analytics",
        "Techniques and Tools of Predictive Analytics",
        "Regression Analysis",
        "Linear Regression",
        "Logistic Regression",
        "Application of Predictive Analytics",
      ],
    },
    {
      title: "Prescriptive Analytics",
      points: [
        "Regression Analysis",
        "Actionable Insights",
        "Implement Recommendations",
      ],
    },
  ];
  

  const syllabus = [
    {
      title: "Introduction to HR Analytics",
      subpoints: [
        "Definition and Importance",
        "Historical Evolution and Trends",
        "HR Metrics vs. HR Analytics",
        "Lead indicators and Lag indicators",
        "Data types and sources",
      ],
    },
    {
      title: "HR Data Management",
      subpoints: [
        "Data Collection and Sources",
        "Internal HR Data",
        "External Data Sources",
        "Data Quality and Integrity",
      ],
    },
    {
      title: "Descriptive Analytics",
      subpoints: [
        "Data Visualization tools (Excel, Tableau)",
        "Various types of Graphs",
        "Dashboards",
        "Statistical Analysis",
        "Measures of Central Tendency",
        "Measures of Dispersion",
        "Range",
        "Variance and Standard Deviation",
        "Correlation",
        "Examples of HR Metrics",
      ],
    },
    {
      title: "Microsoft Excel",
      subpoints: [
        "Workbooks and Worksheets",
        "Ribbon",
        "Menu Items",
        "Quick Access Toolbar",
        "Formula Bar",
        "Status Bar",
        "Basic Navigation",
        "Basic operations",
        "Basic Functions (e.g., Sum, Average, Count, Counta, etc.)",
        "Relative and absolute references",
        "Logical functions (IF, AND, OR)",
        "Conditional Summing",
        "Conditional counting",
        "Lookups (vlookup, hlookup, xlookup)",
        "Text and number formatting",
        "Conditional formatting",
        "Data Validation",
        "Sorting and Filtering",
        "Advanced filtering",
        "Working with tables",
        "Statistical functions (Mean, Mode, Median)",
        "Text functions (Left, Right, Mid, Concat, Concatenate, Find, Search, etc.)",
        "Date functions (Today, Now, Date, Time, Datedif)",
        "Pivot tables",
        "Making graphs, inferences, and recommendations",
      ],
    },
    {
      title: "Working on Tableau",
      subpoints: [
        "Introduction and Installation of the software",
        "Understanding Tableau Interface",
        "Connecting MS Excel to Tableau",
        "Understanding Measures and Dimensions",
        "Types of Graphs/Charts",
        "Creating visualizations",
        "Formatting options",
        "Applying filters",
        "Building Dashboards",
        "Publishing dashboards",
        "Downloading and sharing dashboards",
        "Creating Calculated Fields",
        "Creating combo charts",
      ],
    },
    {
      title: "Predictive Analytics",
      subpoints: [
        "Concept of predictive analytics",
        "Techniques and Tools of Predictive Analytics",
        "Regression Analysis",
        "Linear Regression",
        "Logistic Regression",
        "Application of Predictive Analytics",
      ],
    },
    {
      title: "Prescriptive Analytics",
      subpoints: [
        "Regression Analysis",
        "Actionable Insights",
        "Implement Recommendations",
      ],
    },
  ];
  
  const keyFeatures = [
  "50+ Hours of HR Analytics Training",
  "Certification Preparation for HR Roles",
  "Mentor Support for Data Analysis",
  "Professional Resume Editing",
  "52 Hours of Hands-On Projects",
  "Job-Oriented HR Analytics Program",
  "69 Hours of Self-Paced Learning",
  "Job Assistance for HR Analytics",
  "Free Demo Classes",
  "Affordable Training Fees",
  "Flexible Learning Schedule",
  "1482+ Batches Completed",
  "Real-World HR Analytics Case Studies",
  "Access to HR Analytics Tools",
  "Practical HR Analytics Applications",
  "Mock Interviews and Career Prep",
  "Expert Guidance on Reporting",
  "Interactive HR Analytics Sessions",
  "Post-Training Job Support",
  ];

  const faqs = [
    {
      question: "What is HR Analytics?",
      answer:
        "HR Analytics involves using data-driven insights to enhance HR processes like recruitment, employee performance management, retention, and workforce planning. It allows organizations to make more informed HR decisions by analyzing real-time data and predictive models.",
    },
    {
      question: "How does HR Analytics relate to data-driven decision-making?",
      answer:
        "HR Analytics supports evidence-based decision-making by analyzing data trends, patterns, and key metrics like employee performance, satisfaction, and retention. This helps organizations improve their workforce strategies and overall HR operations.",
    },
    {
      question: "How can I start a career in HR Analytics?",
      answer:
        "To pursue a career in HR Analytics, start by developing a strong understanding of HR processes and analytics tools such as Power BI, Tableau, and Excel. Specialized courses in HR Analytics and data science will help build the skills necessary for analyzing and interpreting HR data.",
    },
    {
      question: "What tools are commonly used in HR Analytics?",
      answer:
        "Common tools in HR Analytics include Power BI, Tableau, Excel, and HR-specific software. These tools help HR professionals visualize data, generate reports, and derive actionable insights from various HR functions such as employee engagement, recruitment, and performance management.",
    },
    {
      question: "What skills are essential for an HR Analytics professional?",
      answer:
        "Essential skills for HR Analytics professionals include proficiency with data analysis and visualization tools like Power BI and Tableau, a deep understanding of HR metrics, knowledge of statistical methods, problem-solving abilities, and the ability to communicate insights clearly to HR decision-makers.",
    },
    {
      question: "What challenges do HR Analytics professionals face?",
      answer:
        "HR Analytics professionals face challenges such as handling large and complex datasets, ensuring data accuracy and privacy, integrating data from multiple HR systems, interpreting data effectively, and adapting to the ever-changing needs of HR departments.",
    },
    {
      question: "How can I improve my HR Analytics skills?",
      answer:
        "To improve HR Analytics skills, gain hands-on experience with HR data analysis, master analytics tools like Power BI and Tableau, learn to interpret HR metrics and KPIs, and stay updated with new trends in HR technologies and data analytics.",
    },
    {
      question: "Are certifications necessary for HR Analytics professionals?",
      answer:
        "Certifications in HR Analytics, data science, or HR-specific tools like Power BI and Tableau can enhance your profile. They validate your skills and commitment to staying up-to-date with HR Analytics trends, helping you stand out in the job market.",
    },
    {
      question: "What is the future scope of HR Analytics?",
      answer:
        "The future of HR Analytics lies in the integration of AI, machine learning, and predictive analytics to optimize workforce management. As HR Analytics continues to evolve, it will offer deeper insights into employee performance, engagement, and retention, driving more strategic HR decisions.",
    },
  ];
  
  const whatsData = [
    {
      title: "What is HR Analytics?",
      points: [
        "HR Analytics, also known as People Analytics, refers to the application of data and analytical techniques to enhance HR functions, such as recruitment, employee performance management, retention, and workforce planning.",
        "It involves gathering and analyzing employee data to uncover trends and insights that support data-driven decision-making in human resources.",
        "Key tools used in HR Analytics include data visualization platforms like Power BI and Tableau, HR management systems like SAP SuccessFactors, and statistical analysis tools such as R and Python.",
        "Professionals from HR, IT, or business analytics backgrounds can pursue a career in HR Analytics by acquiring knowledge of HR processes and analytical tools.",
        "To get started, focus on learning core HR principles and familiarize yourself with popular analytics tools and software. Consider enrolling in IT Accurate's HR Analytics training programs to gain hands-on, industry-specific experience.",
        "Becoming proficient in HR Analytics requires practical experience with data analysis and visualization, as well as the ability to interpret and communicate data-driven insights to HR leaders and decision-makers.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
    { Image: dev9, alt: "Award 8" },
    { Image: dev10, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ];

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
  <h1>Sapalogy - Best HR Analytics Training in Nagpur</h1>
  <div className="stars-section">
    {[...Array(5)].map((_, index) => (
      <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
    ))}
  </div>
  <div className="ratings-info">
    <span className="rating">(4.8)</span>
    <span className="separator">|</span>
    <span className="ratings-count">6229 Ratings</span>
    <span className="separator">|</span>
    <span className="learners-count">5000 Learners</span>
  </div>
  <p>
  Sapalogy’s HR Analytics Training Program offers the best training in HR Analytics, focusing on tools and techniques to analyze employee data, predict workforce trends, and enhance HR decision-making. This program caters to both freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. The course covers key HR analytics tools like SAP SuccessFactors, Power BI, and Tableau, providing hands-on experience to ensure real-world application.
</p>
<p>
  Our job-oriented training ensures that learners not only earn certifications but also gain practical experience equivalent to professionals with 10+ years of experience. We understand the value of your time, so we offer comprehensive training in just 2 months.
</p>


  <h3>Key features of our training include:</h3>
  <ul className="points-list">
    <li>
      <FontAwesomeIcon icon={faFileAlt} /> Professional resume preparation
    </li>
    <li>
      <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview preparation and practice
    </li>
    <li>
      <FontAwesomeIcon icon={faBriefcase} /> 6-month internship program
    </li>
    <li>
      <FontAwesomeIcon icon={faCheckCircle} /> 100% job assistance guaranteed
    </li>
    <li>
      <FontAwesomeIcon icon={faLaptop} /> Access to HR Analytics tools for hands-on practice
    </li>
    <li>
      <FontAwesomeIcon icon={faProjectDiagram} /> Real-time project exposure with SAP SuccessFactors and other analytics tools
    </li>
  </ul>
</div>


        <PageForm />
      </div>

      {/*     
      <div className="video-section">
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/syzh1IxEaak?rel=0&modestbranding=1&loop=1&playlist=syzh1IxEaak"
          title="DevOps Video"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="responsive-iframe"
        ></iframe>
      </div>
    </div> */}
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn HR Analytics" roadmapData={roadmapData} />
<Syllabus heading="HR Analytics Training Syllabus" Syllabus={syllabus} />
<KeyFeatures
  heading="HR Analytics Training Key Features"
  keyFeatures={keyFeatures}
/>


      <BatchesPage Batches={BatchesPage} />

      <JobPreparation />

      <section className="devops-certification-section">
        <h2 className="certification-heading">HR Analytics Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
          <ul>
  <li>
    Sapalogy’s HR Analytics certification will serve as proof that you have completed the HR Analytics course, showcasing your proficiency in analyzing employee data and driving HR decision-making.
  </li>
  <li>
    The HR Analytics certification offered by Sapalogy will equip you with valuable skills in data analysis and visualization tools like Power BI, Tableau, and SAP SuccessFactors, making you more competitive in the HR job market.
  </li>
  <li>
    Sapalogy’s HR Analytics training institute provides comprehensive guidance for your global certifications, ensuring a 100% passing guarantee in examinations such as HR Analytics Certification, Data-Driven HR Decision Making, and various other global exams.
  </li>
</ul>


          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Faq Faqs={faqs} />


      <CustomMarquee />

      <CourseOpportunities pageName="DA_HR" />
      <MarqueeComponent />
      <Contact />
    </div>
  );
};

export default DAHR;
