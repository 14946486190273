import React, { useState } from "react";
import GoogleMap from "../Components/GoogleMap";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    course: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formSubmissionData = {
      access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
      ...formData,
    };

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formSubmissionData),
      });

      const result = await response.json();

      if (result.success) {
        setSuccessMessage("Form submitted successfully!");
        setFormData({
          name: "",
          phone: "",
          course: "",
        });
      } else {
        setErrorMessage("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* Inline Styles */}
      <style>{`
        .contact-form-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 20px;
          padding: 20px;
          border: 0.5px solid gray;
          border-radius: 10px;
          max-width: 900px;
          margin: 2rem auto;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          background-color: transparent;
           margin-top: 5rem;
        }

        .contact-form-content {
          flex: 1;
          min-width: 300px;
          padding: 10px;
        }

        .contact-form-map {
          flex: 1;
          min-width: 300px;
          padding: 10px;
          border-radius: 10px;
          overflow: hidden;
        }

        .contact-form-content h2 {
          text-align: center;
          margin-bottom: 20px;
          color: white;
        }

        .contact-form-content form div {
          margin-bottom: 15px;
        }

        .contact-form-content label {
          font-weight: bold;
          display: block;
        }

        .contact-form-content input,
        .contact-form-content select {
          width: 100%;
          padding: 10px;
          margin: 5px 0;
          border-radius: 5px;
          border: 1px solid #ccc;
        }


         .contact-form-content select{
         color:black;
         }
        .contact-form-content button {
          padding: 10px 20px;
          background-color: #7B1FA2;
          color: white;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }

        .contact-form-content p {
          text-align: center;
          margin-top: 10px;
        }

        .contact-form-content p.success {
          color: green;
        }

        .contact-form-content p.error {
          color: red;
        }

        @media (max-width: 768px) {
          .contact-form-container {
            flex-direction: column;
            align-items: center;

          }

          .contact-form-content,
          .contact-form-map {
            width: 100%;
          
          }
        }
      `}</style>

      <div className="contact-form-container">
        {/* Contact Form */}
        <div className="contact-form-content">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="phone">Phone No.:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Your Phone no."
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="course">Select Your Course:</label>
              <select
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                required
              >
                <option value="">-- Select a Course --</option>
                <option value="SAP">SAP</option>
                <option value="Salesforce">Salesforce</option>
                <option value="AWS">AWS</option>
                <option value="DevOps">DevOps</option>
                <option value="Python">Python</option>
                <option value="AI & ML">AI & ML</option>
                <option value="Data Analytics">Data Analytics</option>
                <option value="Business Analytics">Business Analytics</option>
                <option value="ServiceNow">ServiceNow</option>
                <option value="HR Training">HR Training</option>
                <option value="Share Market">Share Market</option>
              </select>
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
          {successMessage && <p className="success">{successMessage}</p>}
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>

        {/* Google Map */}
        <div className="contact-form-map">
          <GoogleMap />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
