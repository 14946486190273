import React, { useEffect, useRef } from 'react';
import '../Css/DevOps.css';
import certificateImg from '../assets/certificate.webp';
import Contact from '../pages/Contact'
import IMG from '../assets/Work.webp'
import Roadmap from '../Components/Roadmap';
import WhatsDevOps from '../Components/WhatsDevOps'
import PageForm from '../Components/PageForm';
import Syllabus from '../Components/Syllabus';
import KeyFeatures from '../Components/KeyFeatures';
import Faq from '../Components/Faq'
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import MarqueeComponent from '../Components/MarqueeComponent'
import dev1 from '../assets/Projects/Salesforce/1.png';
import dev2 from '../assets/Projects/Salesforce/2.png';
import dev3 from '../assets/Projects/Salesforce/3.png';
import dev4 from '../assets/Projects/Salesforce/4.png';
import dev5 from '../assets/Projects/Salesforce/5.png';
import dev6 from '../assets/Projects/Salesforce/6.png';
import dev7 from '../assets/Projects/Salesforce/7.png';
import dev8 from '../assets/Projects/Salesforce/8.png';
import dev9 from '../assets/Projects/Salesforce/9.png';
import dev10 from '../assets/Projects/Salesforce/10.png';
import rev1 from '../assets/Reviews/Salesforce/sf_rev1.png';
import rev2 from '../assets/Reviews/Salesforce/sf_rev2.png';
import rev3 from '../assets/Reviews/Salesforce/sf_rev3.png';
import rev4 from '../assets/Reviews/Salesforce/sf_rev4.png';
import rev5 from '../assets/Reviews/Salesforce/sf_rev5.png';
import RegularSlider from '../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JobPreparation from '../Components/JobPreparation';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import Marq from '../Components/CustomMarquee';
import BatchesPage from "../Components/Batches";
import CourseOpportunities from '../Components/CourseOpportunities'
const DevOpsPage = () => {






  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'Salesforce crm',
        'What is salesforce?',
        'Salesforce careers',
        'Salesforce course',
        'Salesforce certification',
        'Salesforce job in India ',
        'Salesforce job in nagpur'
      ]
    },
    {
      title: 'Salesforce Admin',
      points: [
        'Salesforce login.',
        'Salesforce trailhead login.',
        'All the topics of salesforce admin are covered in detail.',
      ]
    },
    {
      title: 'Salesforce Development',
      points: [
        'Salesforce developer login.',
        'Basic of C Language.',
        'APEX Programming.',
        'All the topics of salesforce development are covered in detail.'
      ]
    },
    {
      title: 'Salesforce Lwc and Integration',
      points: [
        'What is LWC?',
        'Basic HTML',
        'JavaScript',
        'All the topics of salesforce Lwc and Integration are covered in detail.',
      ]
    },
    {
      title: 'Salesforce Einstein Bots',
      points: [
        'Building a NLP-Powered chatbot',
        'Dialog flows using no-coding configuration',
        'Out of the box functions using APX class',
        'Deploy your Bot to website'
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keyword',
        'Show impact',
        'Include soft skill',
        'Be unique',
      ]
    },
    {
      title: 'Interview Practice',
      points: [
        'How to do face to face online & offline interview',
        'One on One interview sessions',
        'Group discussion',
        'Grooming session',
        'Research the company and follow ups',
      ]
    },
    {
      title: 'Job Opportunities',
      points: [
        'Prepare for your Job search',
        'Ready walkin in India',
        'Tie up references',
        'GET PLACED',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to Apex Programming.',
      subpoints: [
        'Basic Of C language',
        'What is Cloud Computing',
        'APEX Introduction',
        'APEX Classes',
        'APEX Development Process',
        'APEX Development Tools',
        'APEX Governer Limits'
      ],
    },
    {
      title: 'Data Types And Logic Control Statements',
      subpoints: [
        'Overview of Apex Data Types',
        'Primitive Data Types',
        'Non Primitive Datatypes',
        'SObject Data Types',
        'Enum Data Types',
        'Collections Data Types',
        'Apex Operators',
        'Apex Control Statements',
        'Executing Apex Programs'
      ],
    },
    {
      title: 'APEX Classes',
      subpoints: [
        'Apex Classes',
        'Access Modifiers',
        'Apex Class Constructors',
        'Apex Class Variables and Methods',
        'Inheritance, Sharing, and Interface',
        'Accessing Apex Class',
        'Apex Keywords and Methods',
      ],
    },
    {
      title: 'Implementing SOQL And SOSL Queries',
      subpoints: [
        'Standard Object and Field API Names',
        'Relationships in Apex',
        'SOQL Function, SOQL Bindings and SOQL For Loops',
        'Dynamic Query Creation',
        'Relationship Query and its Types',
        'Child to Parent Querry',
        'Parent to Child Querry'
      ],
    },
    {
      title: 'Data Manipulation Language',
      subpoints: [
        ' Data Manipulation Language',
        'Loops in DML',
        'Database.DML Options',
        'Transaction Control',
        'Ways to invoke DML Operations',
        'Invoking DML Operations',
      ],
    },
    {
      title: 'Trigger In APEX',
      subpoints: [
        'Apex Triggers and its Types',
        'Order of Execution of Trigger',
        'Trigger Syntax and Events',
        'Trigger Context Variables',
        'Bulkify Triggers',
        'Trigger Helper Class Pattern',
        'Best Practice of Trigger'
      ],
    },
    {
      title: 'Batch Classes',
      subpoints: [
        'Batch Class In Apex',
        'Batch Class Methods',
        'Schedule Methods In Batch Class',
        'Cronn Expressions',
        'Email Programming',
        'Future Methods',
        'Asynchronous Apex VS Synchronous Apex'
      ],
    },
    {
      title: 'Exception Handling',
      subpoints: [
        'Exceptions',
        'Exception Statements',
        'System-Defined Exception',
        'Exception Methods',
        'Catching Different Exception Types',
        'Try catch and finally methods'
      ],
    },
    {
      title: 'APEX Testing Essentials',
      subpoints: [
        'Write and run Apex Tests',
        'Create Tests data',
        'Running Test Units',
        'Testing Best Practices',
        'Code Coverage',
        'Test Classes Methods'
      ],
    }
  ];

  const keyFeatures = [
    "152+ Hrs Instructor-Led Training",
    "Certficate Guidance",
    "Mentor Support",
    "Resume Editing",
    "52 Hrs Project & Exercises",
    "100% Job Oriented Training",
    "36Hrs Self-Placed Videos",
    "Job Assistance",
    "Free Demo Class Available",
    "Affordable Fees Stucture",
    "Flexible Schedule",
    "Completed 1500+ Batches"
  ];

  const faqs = [
    {
      question: "What is Salesforce?",
      answer: "Salesforce is a cloud-based Customer Relationship Management (CRM) platform that helps businesses manage customer interactions, automate sales processes, and improve marketing strategies.",
    },
    {
      question: "What is Salesforce Development?",
      answer: "Salesforce Development involves customizing the Salesforce platform to meet specific business needs through coding, integration, and app development",
    },
    {
      question: "What Programming Language is used in Salesforce Development?",
      answer: "Apex is the primary programming language used in Salesforce Development, similar to Java, for creating custom logic and functionalities.",
    },
    {
      question: "What is Visualforce in Salesforce?",
      answer: "Visualforce is a markup language used to develop custom user interfaces in Salesforce, allowing developers to create custom pages and components.",
    },
    {
      question: "What are Lightning Components?",
      answer: "Lightning Components are a modern framework for building reusable components in Salesforce, providing a responsive and efficient user experience.",
    },
    {
      question: "How do I Start Learning Salesforce Devlopement?",
      answer: "To start learning Salesforce Development, begin with understanding Salesforce basics, then progress to Apex programming, Visualforce, Lightning Components, and integrations.",
    },
    {
      question: "What is Salesforce DX?",
      answer: "Salesforce DX (Developer Experience) is a set of tools and features designed to streamline the development lifecycle on the Salesforce platform, including version control, scratch orgs, and continuous integration.",
    },
    {
      question: "What are Salesforce Triggers?",
      answer: "Salesforce Triggers are Apex code that executes before or after specific events occur in Salesforce, such as record insertion, update, deletion, or undeletion. Triggers allow developers to automate business processes and enforce data integrity within Salesforce.",
    }
  ];

  const whatsData = [
    {
      title: 'What is Salesforce ?',
      points: [
        'Salesforce is the world’s #1 CRM (CUSTOMER RELATIONSHIP MANAGER ) Platform, It is a software product that help marketing, sales, commerce, services and IT teams to connect with the customers.Salesforce is designed to be user friendly, So it can be learn easily with the right training and resources.',
        'Salesforce is designed to be user friendly, So it can be learn easily with the right training and resources.',
        'IT background, non IT background, Freshers, Experience can start their career in salesfore irrrespective of their background.',
        'Start by understanding the basics of platform, get training with sapalogy and Join user group, Communities, and placement oriented training of salesforce will give you hand experience.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
    { Image: dev9, alt: "Award 8" },
    { Image: dev10, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Salesforce Training in Nagpur</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6367 Learners</span>
          </div>
          <p>
            Sapalogy offers you  the Best Salesforce Training in Nagpur for Fresher and Experience candidate in Nagpur, with Expert Guidance and 100% Guaranteed Placement Assistance. Our  Training Course cover  every technical topics in details and the student is left with Real-Time Hands-On Experience.
          </p>
          <br />
          <p>
            Our Practical, Job-Oriented Training Program, will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of Field Experience. We value your time as much as ours. Hence we intend to train you in Salesforce in just 5 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>

        <PageForm />
      </div>

      <div className="video-section">
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/syzh1IxEaak?rel=0&modestbranding=1&loop=1&playlist=syzh1IxEaak"
          title="DevOps Video"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="responsive-iframe"
        ></iframe>
      </div>
    </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn Salesforce" roadmapData={roadmapData} />
      <Syllabus heading="Salesforce Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Salesforce Training" keyFeatures={keyFeatures} />
      <BatchesPage Batches={BatchesPage} />

      <section className="devops-certification-section">
        <h2 className="certification-heading">Salesforce Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The Salesforce certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your DevOps global certification, ensuring a 100% passing guarantee in examinations.</li>
              <li>Salesforce Platform Development Certification and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

<JobPreparation />


      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">Salesforce Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>
      <Marq />
      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>


      
  <MarqueeComponent />	
      <CourseOpportunities pageName="Salesforce" />
      <Contact />

    </div>
  );
};

export default DevOpsPage;