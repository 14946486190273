import React, { useState } from 'react';
import '../Css/FeesDetails.css';
import {
    Select,
    MenuItem,
    Button,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid
} from '@mui/material';
import Marquee from 'react-fast-marquee';
import { courseData } from '../courses';
import hdfcLogo from '../assets/New folder/hdfclogo.jpg';
import visaLogo from '../assets/New folder/VISA.png'; 
import masterCardLogo from '../assets/New folder/master-card.jpg';
import amexLogo from '../assets/New folder/AMEX-Card.png';
import SBIBANK from '../assets/New folder/SBI.webp';
import AXISBANK from '../assets/New folder/AXISBANK.png';
import BOB from '../assets/New folder/BOB.png';

const calculateEMI = (principal, rate, months) => {
    const monthlyRate = rate / (12 * 100);
    const emi = principal * monthlyRate * Math.pow(1 + monthlyRate, months) / (Math.pow(1 + monthlyRate, months) - 1);
    return emi.toFixed(2);
};

const FeesDetails = () => {
    const [selectedCourse, setSelectedCourse] = useState('');
    const [showEMI, setShowEMI] = useState(false);
    const [selectedEMI, setSelectedEMI] = useState('');

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
        setShowEMI(false);
        setSelectedEMI(''); 
    };

    const handleEMIChange = (event) => {
        setSelectedEMI(event.target.value);
    };

    const handleShowEMI = () => {
        setShowEMI(true);
    };

    const course = courseData.find(course => course.id === selectedCourse);

    return (
        <Box
            sx={{
                padding: '20px',
                margin: '0 auto',
                backgroundColor: 'transparent',
                position: 'relative',
                width: { xs: '95%', sm: '80%', md: '70%' },
                minHeight: '30vh',
                color: '#fff',
                border: '1px solid lightgray',
                borderRadius: '8px',
            }}
        >
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={8} sm={8}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
                        Select Course
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} container justifyContent="flex-end">
                    <img src={hdfcLogo} alt="HDFC Logo" style={{ width: '100%', height: 'auto', marginTop: '-1rem', maxWidth: '180px' }} />
                </Grid>
            </Grid>

            <Select
                value={selectedCourse}
                onChange={handleCourseChange}
                displayEmpty
                fullWidth
                sx={{
                    backgroundColor: 'transparent',
                    borderRadius: '8px',
                    marginBottom: '20px',
                    color: '#fff',
                    '&:focus': {
                        borderColor: 'purple',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'purple',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'purple',
                    },
                }}
            >
                <MenuItem value="" disabled>Select a Course</MenuItem>
                {courseData.map(course => (
                    <MenuItem key={course.id} value={course.id} sx={{ color: '#000' }}>
                        {course.name}
                    </MenuItem>
                ))}
            </Select>

            {course && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ color: '#fff' }}>Price: ₹{course.price}</Typography>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{
                            backgroundColor: '#1976d2',
                            '&:hover': { backgroundColor: '#1565c0' },
                            color: '#fff',
                            padding: '5px 10px',
                        }}
                        onClick={handleShowEMI}
                    >
                        Show EMI Plans
                    </Button>
                </Box>
            )}

            {showEMI && course && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ color: '#fff' }} gutterBottom>Select EMI Plan:</Typography>
                    <RadioGroup
                        row
                        value={selectedEMI}
                        onChange={handleEMIChange}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            backgroundColor: 'transparent',
                            padding: '10px',
                            border: '1px solid lightgray',
                            borderRadius: '10px',
                        }}
                    >
                        {course.emis.map((emi, index) => (
                            <FormControlLabel
                                key={index}
                                value={emi.duration}
                                control={<Radio sx={{ color: '#fff' }} />}
                                label={`${emi.duration} months at ${emi.interest}%`}
                                sx={{ color: '#fff' }}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            )}

            {selectedEMI && course && (
                <Table sx={{ mt: 3, backgroundColor: 'transparent', border: '1px solid lightgray', borderRadius: '10px', width: '100%', tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976d2' }}>
                            <TableCell sx={{ color: '#fff', width: '25%' }}>Duration</TableCell>
                            <TableCell sx={{ color: '#fff', width: '25%' }}>Interest Rate (%)</TableCell>
                            <TableCell sx={{ color: '#fff', width: '25%' }}>Monthly Payment (₹)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {course.emis.filter(emi => emi.duration === selectedEMI).map((emi, index) => {
                            const monthlyPayment = calculateEMI(course.price, emi.interest, parseInt(emi.duration));
                            const totalInterest = (monthlyPayment * parseInt(emi.duration) - course.price).toFixed(2);
                            return (
                                <TableRow key={index}>
                                    <TableCell sx={{ color: '#fff' }}>{emi.duration}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{emi.interest}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{monthlyPayment}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}

    
            <Typography variant="h6" sx={{ mt: 4, color: '#fff', textAlign: 'center' }}>
                We accept all payment modes and cards.
            </Typography>

            <Marquee direction='right' gradient={false} speed={100} style={{ margin: '20px auto', width: '80%' }}>
                <img src={visaLogo} alt="Visa" style={{ width: '50px', margin: '0 20px' }} />
                <img src={masterCardLogo} alt="MasterCard" style={{ width: '100px', margin: '0 20px' }} />
                <img src={hdfcLogo} alt="HDFC bank" style={{ width: '100px', margin: '0 20px' }} />
                <img src={amexLogo} alt="American Express" style={{ width: '100px', margin: '0 20px' }} />
                <img src={SBIBANK} alt="SBI bank" style={{ width: '100px', margin: '0 20px' }} />
                <img src={BOB} alt="Bank of Baroda" style={{ width: '100px', margin: '0 20px' }} />
                <img src={AXISBANK} alt="Axis Bank" style={{ width: '100px', margin: '0 20px' }} />
            </Marquee>
        </Box>
    );
};

export default FeesDetails;
