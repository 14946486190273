import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import SideDropdown from '../../Components/SideDropdown';
import JobPreparation from '../../Components/JobPreparation';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'
import MarqueeComponent from '../../Components/MarqueeComponent'

const DevOpsPage = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  
  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP HCM?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: ' Stakeholder Engagement',
      points: [
        'Engage with key stakeholders, including HR, IT, and business leaders.',
        'Gather feedback on current SAP HCM processes and pain points.',
      ]
    },
    {
      title: 'Cloud Migration Consideration',
      points: [
        'Assess the feasibility of migrating SAP HCM to the cloud.',
        'Explore the benefits of cloud-based solutions for scalability and accessibility.',
      ]
    },
    {
      title: 'Data Security and Compliance',
      points: [
        'Implement and enhance security measures to protect sensitive HR data.',
        'Ensure compliance with relevant data protection regulations and standards.',
      ]
    },
    {
      title: 'Analytics and Reporting',
      points: [
        'Enhance reporting capabilities for HR analytics.',
        'Implement predictive analytics for workforce planning and decision-making.',
      ]
    },
    {
      title: 'User Experience (UX) Improvements',
      points: [
        'Enhance the overall user experience of SAP HCM.',
        'Optimize user interfaces for efficiency and ease of use.',
      ]
    },
    {
      title: 'Post-Implementation Support',
      points: [
        'Develop a robust support and maintenance plan.',
        'Ensure a dedicated team for addressing issues and providing ongoing support.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'SAP HCM - Introduction',
      subpoints: [
        'What is SAP?',
        'SAP ERP Packages',
        'Different Modules in SAP',
        'Functional vs. Technical SAP Modules',
        'Differences between SAP EE 4.7 and ECC 6.0',
        'Overview of SAP HCM Module',
        'Sub-Modules in SAP HCM',
        'SAP Logon and Navigation Steps',
        'System Landscape',
        'Personnel Administration in SAP HR',
        'Structures in HCM',
        'Enterprise Structure',
        'Personnel Structure',
        'Organizational Structure',
      ],
    },
    {
      title: 'Enterprise Structure',
      subpoints: [
        'Define Client',
        'Define Company',
        'Define Company Code',
        'Assign Company Code to Company',
        'Define Personnel Area',
        'Assign Personnel Area to Company Code',
        'Define Personnel Subarea',
      ],
    },
    {
      title: 'Personnel Structure',
      subpoints: [
        'Define Employee Group',
        'Define Employee Subgroup',
        'Assign Employee Subgroup to Employee Group',
        'Define Employee Attributes',
      ],
    },
    {
      title: 'Organizational Structure',
      subpoints: [
        'Create Organizational Unit',
        'Create and Assign Position to Organizational Unit',
        'Create Job and Assign to the Position',
        'Creating Tasks (Job/Position/Person)',
      ],
    },
    {
      title: 'Expert Mode',
      subpoints: ['Overview and Practical Application'],
    },
    {
      title: 'Simple Maintenance',
      subpoints: ['Overview and Implementation'],
    },
    {
      title: 'Organizational Assignment',
      subpoints: ['Define Administrator'],
    },
    {
      title: 'Features',
      subpoints: ['Define Administrator Group (Feature PINCH)'],
    },
    {
      title: 'Integration between Organizational Management and Personnel Administration',
      subpoints: ['Activate Integration (PLOGI ORGA)'],
    },
    {
      title: 'SAP HR – HR Master Data',
      subpoints: ['Overview of HR Master Data'],
    },
    {
      title: 'Hiring an Employee',
      subpoints: [
        'HR Master Data Structure',
        'Processing HR Master Data',
        'Selecting HR Master Data',
        'Info Type Menu',
      ],
    },
    {
      title: 'Actions/Personnel Events',
      subpoints: [
        'Creating Actions',
        'Personnel Action Types',
        'Reasons for Actions',
        'Steps to Hire an Employee',
      ],
    },
    {
      title: 'Time Management',
      subpoints: [
        'Advantages of Time Management',
        'Transaction Codes for Time Recordings',
        'Group Personnel Subarea for Work Schedules',
        'Define Break Schedules',
        'Define Rules for Variants',
        'Define Daily and Period Work Schedules',
        'Set Work Schedule Rules',
        'Assign Work Schedule Rules',
      ],
    },
    {
      title: 'Payroll Configuration',
      subpoints: [
        'Employee Subgroup Grouping for Personnel Calculation Rules (PCR)',
        'Agreement Provision (CAP)',
        'Pay Scale Types and Areas',
        'Assignment of Pay Scale Structure to Enterprise Structure',
        'Default Settings for Pay Scale Data (TARIF)',
        'Revise Pay Scale Groups and Levels',
      ],
    },
    {
      title: 'Wage Types',
      subpoints: [
        'Primary Wage Types (Dialogue Wage Types)',
        'Secondary Wage Types (Technical Wage Types)',
        'Wage Type Catalog and Texts',
        'Define Wage Type Characteristics',
        'Employee and Personnel Subgroup Wage Type Configurations',
        'Check and Revise Wage Type Settings',
      ],
    },
    {
      title: 'Payroll Run',
      subpoints: [
        'Prerequisite Steps for Payroll Run',
        'Period Parameter, Date Modifier, Payroll Area Setup',
        'Generate Payroll Period and Create Control Record',
        'Simulation, Release, Start Payroll, and Corrections',
        'Exit Payroll',
      ],
    },
    {
      title: 'Reporting',
      subpoints: [
        'Identify Personnel in Organizational Structure',
        'Job Index Reports',
        'Evaluate Vacant Positions',
        'Organizational Plan Visualization',
        'HIS (Human Resources Information) Reporting',
      ],
    },
    {
      title: 'Additional Benefits',
      subpoints: [
        '5+ SAP Projects',
        '20+ Topic-wise Tests',
        'Resume Building Assistance',
        'Interview Preparation Sessions',
        '6-Month Internship with CIN Certificate',
        'Job Placement Opportunities',
        'One-on-One Classroom Interview Practice',
      ],
    },
  ];
  

  const keyFeatures = [
    "162+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 200+ Batches",
    "Certifaction guidance",
  ];

  const faqs = [
    {
      question: "What is SAP HCM?",
      answer: "SAP HCM (Human Capital Management) is an integrated suite of applications designed to manage an organization’s HR processes, including payroll, personnel administration, and talent management.",
    },
    {
      question: "How does SAP HCM handle personnel administration?",
      answer: "SAP HCM automates personnel administration by providing a centralized system for managing employee data, organizational structures, and related processes.",
    },
    {
      question: "What is SAP HCM payroll?",
      answer: "SAP HCM payroll is a module that automates the payroll process, calculating employee salaries, taxes, and other deductions based on predefined rules and regulations.",
    },
    {
      question: "What is Talent Management in SAP HCM?",
      answer: "Talent Management in SAP HCM covers recruitment, performance management, and employee development to identify and nurture talent within an organization",
    },
    {
      question: "How does SAP HCM support time management?",
      answer: "SAP HCM’s time management module helps organizations track and manage employee work hours, absences, and leave, ensuring accurate and compliant timekeeping.",
    },
    {
      question: "What is Employee Self-Service (ESS) in SAP HCM?",
      answer: "Employee Self-Service in SAP HCM allows employees to access and manage their personal information, benefits, and other HR-related tasks through a web-based portal.",
    },
    {
      question: "What is Organizational Management in SAP HCM?",
      answer: "Organizational Management in SAP HCM helps define and maintain an organization’s structure, including departments, positions, and reporting relationships.",
    },
    {
      question: "How does SAP HCM integrate with other modules?",
      answer: "SAP HCM seamlessly integrates with other SAP modules like Finance (FI), Controlling (CO), and Material Management (MM) to ensure data consistency across the enterprise.",
    },
    {
        question: "What is Personnel Development in SAP HCM?",
        answer: "Personnel Development in SAP HCM focuses on employee training and development, helping organizations align workforce skills with business goals.",
      },
      {
        question: "How can SAP HCM improve HR processes?",
        answer: "SAP HCM streamlines HR processes, reduces manual efforts, and enhances data accuracy, ultimately improving the efficiency and effectiveness of human resource management within an organization.",
      }
  ];

  const whatsData = [
    {
      title: 'What is SAP HCM ?',
      points: [
        'SAP HCM is an integrated solution for managing human resources processes within an organization.',
        'It covers areas such as personnel administration, organizational management, and time management.',
        'SAP HCM facilitates employee data management, including hiring, promotions, and terminations.',
        'It supports the creation and maintenance of organizational structures and hierarchies.',
        'Time management features help track employee working hours, attendance, and leave.',
        'Payroll processing is a key component, ensuring accurate and timely compensation for employees.',
        'Talent management functionalities aid in identifying, developing, and retaining key talent.',
        'Sapalogy training provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.',
        'IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.',
        'Sapalogy is the best training institute in nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];



  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best SAP HCM Training in Nagpur</h1>
          <p>
          Sapalogy offers you the best SAP HCM(Human Capital Management) training in Nagpur for freshers and experienced candidates in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP training course covers every technical topic in detail and the student is left with real-time hands-on experience.
          </p>
          <br />
          <p>
          Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP HCM with S/4 HANA in just 4 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>

        <PageForm />
      </div>
    
{/* 
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div> */}
        <Roadmap heading="Roadmap to learn SAP HCM" roadmapData={roadmapData} />
        <WhatsDevOps whatsData={whatsData} />
        <Syllabus heading="SAP HCM Training syllabus" Syllabus={syllabus} />
        <KeyFeatures heading="SAP HCM Training" keyFeatures={keyFeatures} />

      
        <BatchesPage Batches={BatchesPage} />

        <JobPreparation/>
      



      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP HCM CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>


      {/* <Roadmap heading="SAP modules" roadmapData={modulesData} /> */}
      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP HCM Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />
{/* 
      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

<CustomMarquee/>
 
      <CourseOpportunities pageName="SAP_HCM" />
      <MarqueeComponent />	
      <Contact />

    </div>
  );
};

export default DevOpsPage;