import React, { useEffect, useRef, useState } from 'react';
import '../Css/DevOps.css';
import certificateImg from '../assets/certificate.webp';
import Contact from '../pages/Contact'
import IMG from '../assets/Work.webp'
import Roadmap from '../Components/Roadmap';
import WhatsDevOps from '../Components/WhatsDevOps'
import PageForm from '../Components/PageForm';
import Syllabus from '../Components/Syllabus';
import KeyFeatures from '../Components/KeyFeatures';
import Faq from '../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
// project slider image
import MarqueeComponent from '../Components/MarqueeComponent'

import dev1 from '../assets/Projects/Aws/1.png';
import dev2 from '../assets/Projects/Aws/2.png';
import dev3 from '../assets/Projects/Aws/3.png';
import dev4 from '../assets/Projects/Aws/4.png';
import dev5 from '../assets/Projects/Aws/5.png';
import dev6 from '../assets/Projects/Aws/6.png';
import dev7 from '../assets/Projects/Aws/7.png';
import dev8 from '../assets/Projects/Aws/8.png';

// rev image
import rev1 from '../assets/Reviews/Aws/1.png';
import rev2 from '../assets/Reviews/Aws/2.png';
import rev3 from '../assets/Reviews/Aws/3.png';
import rev4 from '../assets/Reviews/Aws/4.png';

import RegularSlider from '../Components/RegularSlider';
import { SiAwselasticloadbalancing } from 'react-icons/si';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle,faStar } from '@fortawesome/free-solid-svg-icons';
import JobPreparation from '../Components/JobPreparation';
import Marq from '../Components/CustomMarquee';
import BatchesPage from "../Components/Batches";
import CourseOpportunities from '../Components/CourseOpportunities'

const AWS = () => {


  

  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What iS AWS?',
        'DevOps course',
        'DevOps certification',
        'DevOps jobs in India',
        'DevOps jobs in Nagpur'
      ]
    },
    {
      title: 'Assessment and Planning',
      points: [
        'Evaluate current infrastructure and identify areas for improvement.',
        'Define business goals and objectives that AWS can address.',
        'Conduct a cost analysis to understand the financial implications of migrating to AWS.',
        'Develop a timeline for migration and set realistic milestones.',
      ]
    },
    {
      title: 'Training and Skill Development',
      points: [
        'Identify the skills and knowledge gaps within the team.',
        'Provide training sessions or certifications for AWS services and tools.',
        'Foster a culture of continuous learning to keep the team updated with the latest AWS advancements.',
        'Encourage hands-on experience through labs and practical exercises.',
      ]
    },
    {
      title: ' Proof of Concept (PoC)',
      points: [
        'Select a small, non-critical workload for initial migration.',
        'Design and implement a proof of concept on AWS to validate feasibility.',
        'Gather feedback and address any challenges encountered during the PoC.',
        'Use the PoC as a learning experience to refine the overall migration strategy.',
      ]
    },
    {
      title: 'Migration and Deployment',
      points: [
        'Prioritize workloads based on complexity, dependencies, and business impact.',
        'Develop a detailed migration plan with a phased approach.',
        'Utilize AWS migration tools and services to streamline the process.',
        'Conduct thorough testing at each stage to ensure functionality and performance.',
      ]
    },
    {
      title: 'Optimization and Cost Management',
      points: [
        'Implement best practices for resource optimization and cost control.',
        'Utilize AWS cost management tools to monitor and analyze spending.',
        'Regularly review and adjust resources based on usage patterns and requirements.',
        'Consider reserved instances, spot instances, and other cost-saving strategies.',
      ]
    },
    {
      title: ' Security and Compliance',
      points: [
        'Implement security best practices and configure AWS security features.',
        'Conduct regular security audits and vulnerability assessments.',
        'Ensure compliance with industry regulations and standards.',
        'Educate the team on AWS security practices and encourage a security-first mindset.',
        
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.'
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Cloud Computing Introduction',
      subpoints: [
        'Introduction to Cloud Computing, AWS Architecture, AWS Management Console, Setting up  of the AWS Account.',
      ],
    },
    {
      title: 'Amazon EC2 and Amazon EBS',
      subpoints: [
        ' Amazon EC2, Amazon EBS, Demo of AMI Creation, Backup, Restore, EC2 Services and EBS  persistent storage.',
      ],
    },
    {
      title: 'Amazon Storage Services : S3, RRS, CloudWatch',
      subpoints: [
        'AWS Storage Services: S3, RRS & Glacier, Amazon Cloud Watch, Alerts, Notification.',
      ],
    },
    {
      title: 'Scaling and Load Distribution in AWS ',
      subpoints: [
        ' Amazon Scaling Service: ELB and Auto Scaling.',
      ],
    },
    {
      title: 'AWS VPC & Route 53',
      subpoints: [
        'Amazon VPC with subnets , Gateways , Route tables and Amazon Route 53 overview.',
      ],
    },
    {
      title: 'Identity and Access Management Techniques (IAM) and Amazon  Managed Relational Database (RDS)',
      subpoints: [
        'Amazon IAM Overview, Amazon RDS.',
      ],
    },
    {
      title: 'Multiple AWS Services and Managing the Resources’ Lifecycle',
      subpoints: [
        'AWS CloudFront, AWS DynamoDB, AWS Import / Export , Overview of AWS Products such  as Elastic Beanstalk, Cloud Formation, AWS OpsWorks, SES.',
      ],
    },
    {
      title: 'AWS Architecture and Design',
      subpoints: [
        'AWS Backup and DR Setup, AWS High availability Design, AWS Best Practices (Cost  +Security), AWS Calculator & Consolidated Billing.',
      ],
    },
    {
      title: 'Migrating to Cloud & AWS Case Study',
      subpoints: [
        'AWS Cloud Migration guidelines. AWS Case Study.',
      ],
    },
    {
      title: 'Project',
      subpoints: [
        'Hands on Workshop, Q/A, Overview of AWS Certification.',],
    },
    
  ];

  const keyFeatures = [
    '64+ Hours course duration',
    '100% Job oriented Training',
    'Free demo class available',
    'Completed 185+ Batches',
    'Industry expert faculties',
    'Certifaction guidance',
  ];

  const faqs = [
    {
      question: "What'is AWS?",
      answer: "Amazon Web Services (AWS) is a cloud computing platform that provides a wide range of on-demand services, including computing power, storage, and databases, over the internet.",
    },
    {
      question: "How do I create an EC2 instance on AWS?",
      answer: "Use the AWS Management Console, select EC2, click “Launch Instance,” choose an Amazon Machine Image (AMI), configure instance details, add storage, configure security groups, and review before launching.",
    },
    {
      question: "What is S3 in AWS?",
      answer: "Amazon S3 (Simple Storage Service) is a scalable object storage service that allows users to store and retrieve data. It’s commonly used for backup, storage of static assets, and hosting static websites.",
    },
    {
      question: "How to secure an S3 bucket in AWS?",
      answer: "Apply proper bucket policies, configure access control lists (ACLs), enable versioning, use encryption (SSE-S3, SSE-KMS), and regularly audit and monitor bucket permissions.",
    },
    {
      question: "What is AWS Lambda?",
      answer: "AWS Lambda is a serverless compute service that lets you run code without provisioning or managing servers. It automatically scales and executes code in response to events.",
    },
    {
      question: "How to set up a VPC in AWS?",
      answer: "In the AWS Management Console, navigate to VPC, click “Start VPC Wizard,” choose a configuration (e.g., VPC with a Single Public Subnet), configure VPC settings, and review before creating.",
    },
    {
      question: "What is an IAM role in AWS?",
      answer: "Identity and Access Management (IAM) roles in AWS define a set of permissions for making AWS service requests. Roles are not associated with a specific user but can be assumed by users, services, or AWS resources.",
    },
    {
      question: "How to enable Multi-Factor Authentication (MFA) on AWS?",
      answer: "In the IAM console, go to &quot;Users,&quot; pick the person, click on &quot;Security credentials,&quot; after which set up MFA by choosing both a hardware or virtual MFA device.",
    },
    {
      question: "What is Amazon RDS?",
      answer: "Amazon RDS (Relational Database Service) is a managed relational database carrier that makes it simpler to installation, operate, and scale a relational database inside the cloud.",
    },
    {
      question: "How to monitor AWS resources?",
      answer: "Use AWS CloudWatch for monitoring and creating alarms, AWS Config for tracking resource changes, and AWS Trusted Advisor for best practices and optimization recommendations.",
    },
  ];

  const whatsData = [
    {
      title: 'What is AWS ?',
      points: [
        'Cloud computing platform provided by using Amazon.',
        'Offers a huge range of offerings, inclusive of computing power, garage, databases, gadget learning and extra.',
        'Allows groups to scale and innovate without the want for prematurely investments in hardware.',
        'Provides a dependable and stable infrastructure for hosting packages and services.',
        'Widely used by organizations for fee-powerful and flexible IT solutions.',
        'Sapalogy cover all the statistics analytics competencies to get you employed in 2024.',
        'Sapalogy taining provides AWS training in offline and online mode. Support with real time AWS project based training.',
        'IT background, non IT background, freshers, experience can start their career in irrespective of their background.',
        'Sapalogy is the best training institute in nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    // { Image: rev4, alt: "rev1" },
    
  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best AWS Training in Nagpur</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.7)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5480 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">5807 Learners</span>
          </div>
          <p>
          Best AWS Training by Sapalogy Training Institute offers you the best AWS training in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our AWS Training course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <p>
          Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in AWS in just 2 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>

        <PageForm />
      </div>

      <div className="video-section">
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/vsYIFUePbn0?loop=1&controls=0&modestbranding=1&playlist=vsYIFUePbn0"
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="responsive-iframe"
        ></iframe>
      </div>
    </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn AWS" roadmapData={roadmapData} />
      <Syllabus heading="AWS Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="AWS Training" keyFeatures={keyFeatures} />

      <BatchesPage Batches={BatchesPage} />


      <section className="devops-certification-section">
        <h2 className="certification-heading">AWS Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The AWS certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your AWS global certification, ensuring a 100% passing guarantee in examinations such as AWS Certification, AWS Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
<JobPreparation/>


      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">AWS Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>
      <Marq />


      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>


      <MarqueeComponent />	
      <CourseOpportunities pageName="AWS" />
      <Contact />

    </div>
  );
};

export default AWS;