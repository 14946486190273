import React from "react";
import "../Css/TrainerDetails.css";

const TrainerDetails = () => {
  return (
    <div className="trainer-container">
      <h2 className="trainer-heading">About Our Trainer</h2>
      <ul className="trainer-list">
  <li>
    <strong>Our training programs</strong> are delivered by certified SAPALOGY trainers with over 7 years of expertise in their respective domains. They are currently working with top MNCs, ensuring real-world insights.
  </li>
  <li>
    Our trainers actively work on live projects and incorporate these into training sessions, making the learning experience practical and industry-relevant.
  </li>
  <li>
    They bring their expertise from leading companies like Cognizant, Dell, Infosys, IBM, L&T InfoTech, TCS, and HCL Technologies.
  </li>
  <li>
    Beyond training, our trainers help candidates secure placements through employee referrals and internal hiring opportunities at their organizations.
  </li>
  <li>
    Passionate about education, our trainers are industry experts dedicated to providing <strong>exceptional training</strong> tailored to each student’s career growth.
  </li>
  <li>
    SAPALOGY is honored to have received multiple awards for training excellence from esteemed IT organizations, reflecting our commitment to quality education.
  </li>
</ul>


    </div>
  );
};

export default TrainerDetails;
