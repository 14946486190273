import React, { useEffect, useRef } from "react";
import "../Css/DevOps.css";
import certificateImg from "../assets/certificate.webp";
import Contact from "../pages/Contact";
import IMG from "../assets/Work.webp";
import Roadmap from "../Components/Roadmap";
import WhatsDevOps from "../Components/WhatsDevOps";
import PageForm from "../Components/PageForm";
import Syllabus from "../Components/Syllabus";
import KeyFeatures from "../Components/KeyFeatures";
import Faq from "../Components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// project slider image
import MarqueeComponent from '../Components/MarqueeComponent'
import dev1 from "../assets/Projects/ML/1.png";
import dev2 from "../assets/Projects/ML/2.png";
import dev3 from "../assets/Projects/ML/3.png";
import dev4 from "../assets/Projects/ML/4.png";
import dev5 from "../assets/Projects/ML/5.png";
import dev6 from "../assets/Projects/ML/6.png";
import dev7 from "../assets/Projects/ML/7.png";
import dev8 from "../assets/Projects/ML/8.png";

import rev1 from "../assets/Reviews/ML/1.png";
import rev2 from "../assets/Reviews/ML/2.png";
import rev3 from "../assets/Reviews/ML/3.png";
import rev5 from "../assets/Reviews/ML/5.png";
import rev6 from "../assets/Reviews/ML/6.png";
import RegularSlider from "../Components/RegularSlider";
import JobPreparation from "../Components/JobPreparation";
import Marq from "../Components/CustomMarquee";
import BatchesPage from "../Components/Batches";
import CourseOpportunities from "../Components/CourseOpportunities";

const MachineLearning = () => {
  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is Machine learning?",
        "Machine learning course",
        " Machine learning certification",
        " Machine learning jobs in india",
        " Machine learning jobs in nagpur",
      ],
    },
    {
      title: "Foundational Skills",
      points: [
        " Learn programming languages such as Python or R.",
        " Develop a solid understanding of mathematics and statistics.",
        " Acquire proficiency in using essential tools and libraries like Jupyter Notebooks, NumPy, and Pandas.",
      ],
    },
    {
      title: "Data Acquisition and Cleaning",
      points: [
        " Gain expertise in obtaining and collecting data from various sources.",
        " Master techniques for cleaning and preprocessing data to ensure its quality and reliability.",
        " Explore data storage solutions and databases. ",
      ],
    },
    {
      title: "Exploratory Data Analysis (EDA)",
      points: [
        "Learn exploratory data analysis techniques to understand the structure and patterns within the data.",
        "Use data visualization tools like Matplotlib and Seaborn to create meaningful plots.",
        "Develop the ability to ask relevant questions about the data and derive insights.",
      ],
    },
    {
      title: "Machine Learning Fundamentals",
      points: [
        "Understand the basics of machine learning algorithms and their applications.",
        "Learn how to train and evaluate models using popular frameworks like scikit-learn.",
        "Explore supervised and unsupervised learning techniques.",
      ],
    },
    {
      title: "Advanced Machine Learning and Deep Learning",
      points: [
        " Dive into more advanced machine learning topics such as ensemble methods and hyperparameter tuning.",
        " Familiarize yourself with deep learning concepts and frameworks like TensorFlow or PyTorch.",
        " Experiment with neural networks and understand their architectures.",
      ],
    },
    {
      title: "Model Deployment and Communication",
      points: [
        "Learn how to deploy machine learning models to production environments.",
        "Develop skills in model interpretation and explainability.",
        "Practice effective communication of data-driven insights to both technical and non-technical stakeholders.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction to Python",
      subpoints: [
        "Python Basics",
        "Python Functions and Packages",
        "Working with Data Structures, Arrays,",
        "Vectors & Data Frames",
        "Google colab notebook",
        "Pandas, NumPy, Matplotlib, Seaborn",
      ],
    },
    {
      title: "Applied Statistics",
      subpoints: [
        "Descriptive Statistics",
        "Probability & Conditional Probability",
        "Hypothesis Testing",
        "Inferential Statistics",
        "Probability Distributions",
      ],
    },
    {
      title: "EDA and Data Processing",
      subpoints: [
        "Data Types",
        "Dispersion & Skewness",
        "Uni & Multivariate Analysis",
        "Data Imputation",
        "Identifying and Normalizing Outlier",
      ],
    },
    {
      title: "Supervised Learning",
      subpoints: [
        "Linear Regression",
        "Multiple Variable Linear Regression",
        "Logistic Regression",
        "Naive Bayes Classifiers",
        "k-NN Classification",
        "Support Vector Machines",
        "3 PROJECTS ON SUPERVISED LEARNING",
      ],
    },
    {
      title: "Unsupervised Learning",
      subpoints: [
        "K-means Clustering",
        "Hierarchical Clustering",
        "Dimension Reduction-PCA",
        "3 PROJECTS ON UNSUPERVISED LEARNING",
      ],
    },
    {
      title: "Ensemble Techniques",
      subpoints: [
        "Decision Trees",
        "Bagging",
        "Random Forests",
        "Boosting",
        "3 PROJECTS ON Ensemble Techniques",
      ],
    },
    {
      title: "Featurization, Model Selection & Tuning",
      subpoints: [
        "Feature Engineering",
        "Model Selection and Tuning",
        "Model Performance Measures",
        "Regularising Linear Models",
        "MI Pipeline",
        "Bootstrap Sampling",
        "Grid Search Cv",
        "Randomised Search Cv",
        "K Fold Cross-validation",
        "A PROJECT ON Featurization, Model Selection & Tuning",
      ],
    },
    {
      title: "Introduction to Reinforcement Learning(RL)",
      subpoints: [
        "RL Framework",
        "Component of RL Framework",
        "Examples of RL Systems",
        "Types of RL Systems",
        "Q-learning",
      ],
    },
    {
      title: "Introduction to SQL",
      subpoints: [
        "Introduction To DBMS",
        "ER Diagram",
        "Schema Design",
        "Key Constraints and Basics of Normalization.",
        "Joins",
        "Subqueries Involving Joins and Aggregations",
        "Sorting",
        "Independent Subqueries",
        "Correlated Subqueries",
        "Analytic Functions.",
        "Set Operations",
        "Grouping and Filtering",
        "2 PROJECTS ON DBMS",
      ],
    },
    {
      title: "ChatGPT and OpenAI",
      subpoints: [
        "-Timeline of NLP and Generative Al",
        "Frameworks for Understanding ChatGPT",
        "and Generative Al",
        "-Implications for Work, Business, and",
        "Education",
        "-Output Modalities and Limitations",
        "-Business Roles to Leverage ChatGPT",
        "-Prompt Engineering for Fine-Tuning",
        "Outputs",
        "-Practical Demonstration and Bonus",
        "Section on RLHF",
        "-Introduction to Generative Al",
        "-Al vs ML vs DL vs GenAl",
        "-Supervised vs Unsupervised Learning.",
        "-Discriminative vs Generative Al",
        "A Brief Timeline of GenAl",
        "-Basics of Generative Models",
        "Large Language Models",
        "-Word Vectors",
        "-ChatGPT: The Development Stack",
        "-Attention Mechanism",
        "-Business Applications of ML, DL and GenAl",
        "-Hands-on Bing Images and ChatGPT",
        "-2 PROJECTS ON ChatGPT  ",
      ],
    },
  ];

  const keyFeatures = [
    "154+ Hrs Instructor-Led Training",
    "32 Hrs Project & Exercises",
    "Flexible Schedule",
    "30 Hrs Self-paced videos",
    "Job Assistance",
    "NASSCOM Certification",
  ];

  const faqs = [
    {
      question: "What is Machine Learning?",
      answer:
        "Machine learning falls under the umbrella of artificial intelligence and focuses on designing algorithms and models that empower computers to learn from data and autonomously make forecasts and choices, bypassing the need for explicit instructions.",
    },
    {
      question: "Supervised vs. Unsupervised Learning: What’s the difference?",
      answer:
        "In supervised learning, the algorithm is trained on a labeled dataset with input-output pairs, while unsupervised learning deals with unlabeled data, aiming to find patterns or relationships within the data.",
    },
    {
      question: "What is a Neural Network?",
      answer:
        "A neural network is a computational model inspired by the human brain, consisting of interconnected nodes (neurons) organized into layers. It’s commonly used for tasks like pattern recognition and decision-making",
    },
    {
      question: "What is overfitting in machine learning?",
      answer:
        "Overfitting occurs when a model learns the training data too well, capturing noise or outliers and performing poorly on new, unseen data. It’s essential to strike a balance to create a model that generalizes well.",
    },
    {
      question: "What is feature engineering?",
      answer:
        "Feature engineering involves selecting, transforming, or creating relevant features (variables) from raw data to improve a machine learning model’s performance.",
    },
    {
      question: "What is the difference between regression and classification?",
      answer:
        "Regression predicts a continuous outcome, while classification predicts a categorical outcome. For example, predicting house prices is a regression task, while spam detection is a classification task.",
    },
    {
      question: "Explain precision and recall in classification",
      answer:
        "Precision measures the accuracy of positive predictions, while recall gauges the ability of a model to capture all relevant instances. Balancing both is crucial for a comprehensive evaluation of a classifier.",
    },
    {
      question: "What is reinforcement learning?",
      answer:
        "Reinforcement learning involves training an agent to make sequences of decisions in an environment to maximize a cumulative reward. It’s often used in tasks where an agent learns to navigate and make decisions through trial and error.",
    },
    {
      question: "What is the role of hyperparameters in machine learning?",
      answer:
        "Hyperparameters are settings external to the model that affect its learning process. Examples include learning rates and regularization parameters. Tuning these hyperparameters is crucial for optimizing a model’s performance.",
    },
    {
      question:
        "How does deep learning differ from traditional machine learning?",
      answer:
        "Deep learning is a subset of machine learning that specifically involves neural networks with multiple layers (deep neural networks). It excels in tasks like image and speech recognition, leveraging hierarchical feature representations.",
    },
  ];

  const whatsData = [
    {
      title: "What is Machine learning?",
      points: [
        "Machine learning is a subset of synthetic intelligence that includes the development of algorithms and models that permit computers to research from information and enhance their overall performance over the years.",
        "It specializes in the introduction of systems which can mechanically examine and interpret information, permitting machines to make predictions or decisions without express programming.",
        "Machine mastering algorithms use statistical techniques to permit computers to pick out patterns, make sense of complex facts, and adapt their behavior based totally at the data available.",
        "The aim of machine mastering is to expand systems that may generalize from past reports, allowing them to manage new, unseen facts and tasks efficaciously.",
        "Sapalogy cover all the Machine learning skills to get you hired in 2024.",
        "Sapalogy taining provides Machine learning training in offline and online mode. Support with real time Machine learning project based training.",
        "IT background, non IT background, freshers, experience can start their career in Machine learning irrespective of their background.",
        "Sapalogy is the best training institute in nagpur with the 100% job opportunities.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },

    { Image: rev5, alt: "rev1" },
    { Image: rev6, alt: "rev1" },
  ];

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best Machine learning Training in Nagpur</h1>

          <p>
            Best Machine learning Training by Sapalogy offers you the best
            Machine learning training in Nagpur for freshers and experience
            candidate in Nagpur, with expert guidance and 100% guaranteed
            placement assistance. Our Machine learning training course covers
            every technical topics in details and the student is left with
            real-time hand on experience.
          </p>
          <p>
            Our practical, Job-oriented training program will not only provide
            you with a certificate but also with knowledge equivalent to around
            2+ years of field experience. We value your time as much as over.
            Hence we invented to train you in Machine learning in just 4 months.
          </p>
        </div>

        <PageForm />
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <div className="video-section">
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/Iz8gR_yoTV4?rel=0&modestbranding=1&loop=1&playlist=Iz8gR_yoTV4"
            title="YouTube Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="responsive-iframe"
          ></iframe>
        </div>
      </div>
      <Roadmap
        heading="Roadmap to Learn Machine Learning"
        roadmapData={roadmapData}
      />
      <Syllabus
        heading="Machine Learning Training Syllabus"
        Syllabus={syllabus}
      />
      <KeyFeatures
        heading="Machine Learning Training"
        keyFeatures={keyFeatures}
      />

      <BatchesPage Batches={BatchesPage} />

      <section className="devops-certification-section">
        <h2 className="certification-heading">
          Machine learning certification
        </h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                Sapalogy training certification will serve as proof that the
                courses were completed by Sapalogy.
              </li>
              <li>
                The Machine learning certification offered by Sapalogy will
                equip you with valuable skills, enhancing your competitiveness
                in the job market.
              </li>
              <li>
                Sapalogy provides comprehensive guidance for your Machine
                learning global certification, ensuring a 100% passing guarantee
                in examinations such as Machine learning Certification, Machine
                learning Platform Development Certification, and various other
                global exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">Machine Learning Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>
      <JobPreparation />
      <Marq />
      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>

 
      <MarqueeComponent />	
      <CourseOpportunities pageName="ML" />
      <Contact />
    </div>
  );
};

export default MachineLearning;
