import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';
import JobPreparation from "../../Components/JobPreparation";
import CustomMarquee from "../../Components/CustomMarquee";
import CourseOpportunities from "../../Components/CourseOpportunities";
import BatchesPage from "../../Components/Batches";
import MarqueeComponent from '../../Components/MarqueeComponent'

const SAPQM = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);

  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];
  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP QM?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: 'Data Migration and Cleanup',
      points: [
        'Evaluate existing quality data for accuracy and completeness.',
        'Develop a data migration plan to transfer relevant data to SAP QM.',
        'Cleanse and standardize data to ensure consistency.',
      ]
    },
    {
      title: 'Configuration and Customization',
      points: [
        "Configure SAP QM modules based on organizational requirements.",
        "Customize workflows and processes to align with best practices.",
        "Implement specific features such as inspection plans and quality notifications",
      ]
    },
    {
      title: 'User Acceptance Testing(UAT)',
      points: [
        "Develop test scripts covering various QM scenarios.",
        "Conduct UAT with end-users to validate system functionality.",
        "Address and resolve any issues identified during testing.",
      ]
    },
    {
      title: 'Pilot Implementation',
      points: [
        'Implement SAP QM in a controlled pilot environment.',
        'Gather feedback from users and make necessary adjustments.',
        'Evaluate the system’s performance and reliability.',
      ]
    },
    {
      title: 'Continuous Improvement',
      points: [
        'Establish a feedback loop for continuous improvement.',
        'Monitor key performance indicators and adjust processes accordingly.',
        'Implement updates and enhancements as needed.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Intruduction to SAP QM',
      subpoints: [
        "Overview of Quality Management in SAP",
        "Integration with other SAP modules",
      ],
    },
    {
      title: 'QM Master Data',
      subpoints: [
        'Material Master for QM',
        'Inspection Plans',
        'Master Inspection Characteristics',
        'Sampling Procedures',
      ],
    },
    {
      title: 'QM in Procurement',
      subpoints: [
        'Vendor Evaluation',
        'Goods Receipt Inspection',
      ],
    },
    {
      title: 'QM in Production',
      subpoints: [
        'In-Process Inspection',
        'Inspection Points',
        'Results Recording',
      ],
    },
    {
      title: 'QM in Sales and Distribution',
      subpoints: [
        'Customer Complaints',
        'Returns',
      ],
    },
    {
      title: 'QM Notifications',
      subpoints: [
        'Defects Recording',
        'Problem Notification and Processing',
      ],
    },
    {
      title: 'Test Equipment Management',
      subpoints: [
        "Calibration Planning",
        "Results Recording for Calibration"
      ],
    },
  ];

  const keyFeatures = [
    '124+ Hours course duration',
    '100% Job oriented training',
    'Industry expert faculties',
    'Free demo class available',
    'Certification guidance',
    'Completed 200+ batches',

  ];

  const faqs = [
    {
      question: "What is SAP QM?",
      answer: "SAP QM (Quality Management) is a module in SAP ERP that helps organizations manage quality processes throughout the product lifecycle, from procurement to manufacturing to customer delivery.",
    },
    {
      question: "How to create a Quality Notification in SAP QM?",
      answer: "Transaction code QM01 can be used to create a Quality Notification in SAP QM. Enter relevant data such as material, plant, and defect details.",
    },
    {
      question: "What is the purpose of the Inspection Lot in SAP QM?",
      answer: "The Inspection Lot is a key concept in SAP QM. It represents a quantity of material that is subject to inspection. Inspection Lots are created during goods receipt, production, or when triggered manually.",
    },
    {
      question: "How to perform a Goods Receipt Inspection in SAP QM?",
      answer: "Use transaction code QA32 to display the Inspection Lot. Enter the lot number and navigate to the “Inspection Lot Stock” tab to view and process the goods receipt inspection results.",
    },
    {
      question: "Explain the usage of Inspection Plans in SAP QM.",
      answer: "Inspection Plans in SAP QM define the inspection scope and criteria for materials. They specify the inspection characteristics, sampling procedures, and the frequency of inspections for a material.",
    },
    {
      question: "What is the difference between Quality Inspection and Quality Certificates in SAP QM?",
      answer: "Quality Inspection refers to the process of inspecting goods to ensure they meet defined quality standards. Quality Certificates are documents that confirm a product’s compliance with specified requirements and are generated after a successful inspection.",
    },
    {
      question: "How to perform a result recording in SAP QM?",
      answer: "Use transaction code QA32 to record results for inspection characteristics. Select the Inspection Lot, enter results for each characteristic, and save.",
    },
    {
      question: "What is the significance of Inspection Points in SAP QM?",
      answer: "Inspection Points are stages in the production or procurement process where inspections are performed. They allow for the monitoring and control of quality at specific points in the process.",
    },
    {
      question: "How to integrate SAP QM with other SAP modules?",
      answer: "SAP QM can be integrated with other SAP modules like Materials Management (MM), Production Planning (PP), and Sales and Distribution (SD) to ensure seamless flow of information and data related to quality management.",
    },
    {
      question: "What is the purpose of the Quality Info Record in SAP QM?",
      answer: "The Quality Info Record in SAP QM stores information about the quality of a material from a specific vendor. It includes details such as inspection specifications and sampling procedures for the material from that vendor.",
    },
  ];

  const whatsData = [
    {
      title: 'About SAP QM Training Course',
      points: [
        "SAP QM is a module within SAP ERP that focuses on quality management processes.",
        "It ensures products meet defined quality standards and regulatory requirements.",
        "QM integrates with other SAP modules like MM (Material Management) and PP (Production Planning).",
        "It covers key quality processes such as inspection, testing, and quality notifications.",
        "QM enables the tracking of quality data throughout the entire supply chain.",
        "It supports the creation and management of inspection plans for products.",
        "Sapalogy training provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.",
        "IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.",
        "Sapalogy is the best training institute in nagpur with the 100% job opportunities."
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //   { Image: dev1, alt: "Award 1" },
  //   { Image: dev2, alt: "Award 2" },
  //   { Image: dev3, alt: "Award 3" },
  //   { Image: dev4, alt: "Award 4" },
  //   { Image: dev5, alt: "Award 5" },
  //   { Image: dev6, alt: "Award 6" },
  //   { Image: dev7, alt: "Award 7" },
  //   { Image: dev8, alt: "Award 8" },
  // ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]

  return (
    <div className="devops-page">
         <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Sapalogy- Best SAP QM Training in Nagpur</h1>
          <p>Sapalogy offers you the Best SAP QM (Quality Management) Training in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP training course covers every technical topics in details and the student is left with real-time hand on experience.</p>
          <br />
          <p>Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP QM with S/4 HANA in just 4 months.</p>
          <ul className='points-list'>
            <li>End user 1 month (ECC / S4 HANA)</li>
            <li>Configuration 2 months(ECC/S4 HANA)</li>
            <li>Project 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>
        <PageForm />
      </div>
    

      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div> */}
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn SAP QM" roadmapData={roadmapData} />
      <Syllabus heading="SAP QM Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP QM Training" keyFeatures={keyFeatures} />

      <BatchesPage Batches={BatchesPage} />

<JobPreparation/>
      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP QM CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />
      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP QM Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}
      <CustomMarquee />
      <CourseOpportunities pageName="SAP_QM" />
      <MarqueeComponent />	
      <Contact />

    </div>
  );
};

export default SAPQM;