import React, { useState, useEffect } from "react";
import logo from "../assets/Logo.webp"; // Import the custom logo

const SalaryHikeSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Paraphrased slides content
  const slides = [
    {
      id: 1,
      text: "Over 50% of participants experienced a salary increase of 50% or more after completing the program*",
      logo: logo, // Using the imported logo image
      subText: "Committed to enhancing global work environments",
      title: "Process Advisors",
    },
    {
      id: 2,
      text: "More than 80% of graduates found employment within three months of completing the program.",
      logo: logo, // Using the imported logo image
      subText: "Consulting excellence at a global scale",
      title: "Career Advisors",
    },
    {
      id: 3,
      text: "A high 90% of alumni recommend this program to others for career advancement.",
      logo: logo, // Using the imported logo image
      subText: "Trusted by industry leaders worldwide",
      title: "Success Advisors",
    },
  ];

  // Automatic slide transition
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer); // Cleanup on unmount
  }, [slides.length]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-[#E9ECEF] rounded-lg shadow-lg p-4 md:p-8">
      {/* Left Section */}
      <div className="flex items-center gap-4">
        <img
          src={slides[currentIndex].logo}
          alt="Logo"
          className="w-16 h-16 object-contain"
        />
        <div>
          <p className="text-sm font-semibold text-gray-500">
            {slides[currentIndex].title}
          </p>
          <p className="text-xs text-gray-400">{slides[currentIndex].subText}</p>
        </div>
      </div>

      {/* Middle Section */}
      <div className="mt-4 md:mt-0 text-center md:text-left flex flex-col items-center md:items-start">
        <p className="text-lg md:text-xl font-bold text-gray-700">
          {slides[currentIndex].text}
          <span className="text-xs text-gray-500">*</span>
        </p>
        {/* Apply Now Button */}
        <a href="/contact"><button className="mt-4 px-6 py-2 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition-all duration-200">
          Apply Now
        </button></a>
      </div>

      {/* Right Section */}
      <div className="flex items-center gap-2 mt-4 md:mt-0">
        <button
          className="text-purple-600 hover:text-purple-800"
          onClick={handlePrevious}
        >
          &#8592;
        </button>
        <button
          className="text-purple-600 hover:text-purple-800"
          onClick={handleNext}
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default SalaryHikeSection;
