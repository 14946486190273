
import React from 'react';
import '../Css/WhatsApp.css'; // Importing the CSS for styling

const WhatsAppIcon = () => {
  const handleClick = () => {
    window.open("https://wa.me/9175978889", "_blank"); // Correct URL format for WhatsApp
  };

  return (
    <div className="whatsapp-icon" onClick={handleClick}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
    </div>
  );
};

export default WhatsAppIcon;
