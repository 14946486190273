import React from 'react';
import '../Css/DevOps.css';
import { useForm } from 'react-hook-form';

const PageForm = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = async (data) => {
        try {
            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
                    name: data.name,
                    phone: data.phone,
                    course: data.course,
                    email: "shivanihiware77@gmail.com",
                }),
            });
            const result = await response.json();
            
            console.log("Response:", result); // Log the response
            
            if (result.success) {
                console.log("Form submitted successfully");
                reset(); // Reset the form fields after successful submission
                console.log("Form has been reset"); // Debug log
            } else {
                console.error("Form submission error:", result);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div className="devops-contact-form">
            <h2>Contact Us Now!</h2>
            <form className="contact-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                      placeholder="Your Name"
                    {...register('name', { required: true })}
                    required
                />  
                {errors.name && <p>Name is required</p>}

                <label htmlFor="phone">Phone No:</label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                      placeholder="Your Phone No"
                    {...register('phone', { required: true })}
                    required
                />
                {errors.phone && <p>Phone number is required</p>}

                <label htmlFor="course">Select Your Course:</label>
                <select
                    id="course"
                    name="course"
                    {...register('course', { required: true })}
                    required>
                    <option value="salesforce">Salesforce</option>
                    <option value="SAP">SAP</option>
                    <option value="data analytics">Data Analytics</option>
                    <option value="business analytics">Business Analytics</option>
                    <option value="data science">Data Science</option>
                    <option value="devops">DevOps</option>
                    <option value="aws">AWS</option>
                    <option value="aiml">AI ML</option>
                    <option value="machine learning">Machine Learning</option>
                    <option value="full stack">Full Stack Development</option>
                    <option value="sharemarket">ServiceNow</option>
                    <option value="HR Training">HR Training</option>
                    <option value="servicenow">Sharemarket</option>
                    <option value="python">Python</option>
                    <option value="Tally">Tally</option>
                </select>
                {errors.course && <p>Please select a course</p>}

                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </div>
    );
};

export default PageForm;
