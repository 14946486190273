import React from 'react';

const RefundPolicy = () => {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6',marginTop:'4rem' }}>
      <h1>Refund and Course Rescheduling Policy</h1>
      <p>
        Thank you for choosing and purchasing <strong>Sapalogy Training</strong> courses. We want to ensure that our users have a rewarding experience while discovering information, assessing, and purchasing our training courses, whether it is for online or classroom training courses. As with any online purchase experience, the following are the terms and conditions that govern the Refund Policy. <strong>Sapalogy Training does not guarantee placements or endorse guarantees provided by others.</strong> When you buy an online or offline training course from Sapalogy Training, you agree to our <strong>Privacy Policy</strong> and <strong>Terms of Use</strong> policy and the points below.
      </p>

      <h3>Cancellation and Refunds: Online and Classroom Learning</h3>
      <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
        <li>
          Sapalogy Training reserves the right to postpone/cancel an event because of instructor illness or force-majeure events (like floods, earthquakes, political instability, pandemic situations like COVID-19, etc.).
        </li>
        <li>
          There is no refund policy for online or classroom training. If you accidentally applied for another course or wish to switch to another course, it will be executed in accordance with availability. For any changes, you may reach us through <strong>admin@sapalogytraining.com</strong>.
        </li>
        <li>No refund will be provided on discounted courses.</li>
        <li>No refund will be provided for the academic institute tie-up courses.</li>
        <li>
          There is no refund applicable on all types of global certification programs. All enrollments and payments made are full and final.
        </li>
        <li>
          If the delegate (student) doesn’t turn up on the given schedule, no refund will be provided.
        </li>
        <li>In the case of a refund, taxes amount will not be refunded.</li>
      </ul>

      <p><strong>Note:</strong> All refunds will be processed within 30 working days, post review and approval of any such request.</p>

      <h3>Non-liability for Customer Claims</h3>
      <p>
        Sapalogy Training will not be liable for any payment or funds debited from a customer’s bank account on the following grounds, and no liability shall arise for Sapalogy Training in respect of such customer claims:
      </p>
      <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
        <li>Disputing a transaction as not done or authorized by him/her.</li>
        <li>
          The charge/debit on the customer’s bank account has occurred because of hacking, phishing, or breach of security/encryption of the customer’s personal data through a platform or any other third-party platform other than that provided by Sapalogy Training.
        </li>
        <li>
          The customer claiming a refund of the amounts deducted from his/her bank account on any ground whatsoever, including dissatisfaction with the Client’s sale of goods and/or services.
        </li>
      </ul>
    </div>
  );
};

export default RefundPolicy;
