import React, { useEffect, useState, useCallback } from 'react';
import { client } from '../contentful/client';
import '../Css/Slider.css'; // Import the CSS file

const Slider = () => {
    const [isSliderLoading, setIsSliderLoading] = useState(true);
    const [sliderSlides, setSliderSlides] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Clean up slide data
    const cleanUpSlides = useCallback((rawData) => {
        const cleanSlides = rawData.map((slide) => {
            const { sys, fields } = slide;
            const { id } = sys;
            const slideImg = fields.img?.fields?.file?.url || '';
            const formattedSlideImg = slideImg.startsWith('http') ? slideImg : `https:${slideImg}`;
            return { id, slideImg: formattedSlideImg };
        });
        setSliderSlides(cleanSlides);
    }, []);

    // Fetch slides from Contentful
    const getSlides = useCallback(async () => {
        setIsSliderLoading(true);
        try {
            const response = await client.getEntries({ content_type: 'slider' });
            cleanUpSlides(response.items);
        } catch (error) {
            console.error('Error fetching slides:', error);
        } finally {
            setIsSliderLoading(false);
        }
    }, [cleanUpSlides]);

    useEffect(() => {
        getSlides();
    }, [getSlides]);

    // Navigate to the next slide
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderSlides.length);
    };

    // Navigate to the previous slide
    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + sliderSlides.length) % sliderSlides.length);
    };

    return (
        <div className="slider-container">
            <h1>Our Upcoming Batches</h1>
            {isSliderLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="slider">
                    <button className="prev" onClick={prevSlide}>&#10094;</button>
                    <div
                        className="slider-inner"
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                        {sliderSlides.map((slide, index) => (
                            <div
                                key={slide.id}
                                className={`slide ${index === currentIndex ? 'center-slide' : ''}`}
                            >
                                <img src={slide.slideImg} alt={`Slide ${slide.id}`} />
                            </div>
                        ))}
                    </div>
                    <button className="next" onClick={nextSlide}>&#10095;</button>
                </div>
            )}
        </div>
    );
};

export default Slider;
