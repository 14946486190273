const placedStudentsData = [
  {
    name: "Aarav ",
    company: "TCS",
    skills: ["Data Analytics", "SQL", "Python"],
    role: "Data Analyst",
    photo:
      "https://this-person-does-not-exist.com/img/avatar-gene874dcc8deda6553fbfdf6e8b83554f7.jpg",
  },
  {
    name: "Ishita",
    company: "Capgemini",
    skills: ["Salesforce", "CRM Management"],
    role: "Salesforce Developer",
    photo:
      "https://img.freepik.com/free-photo/woman_53876-71214.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Karan ",
    company: "Wipro",
    skills: ["SAP FICO"],
    role: "SAP FICO Engineer",
    photo:
      "https://img.freepik.com/premium-photo/portrait-young-man-against-blue-background_1048944-13176074.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Priya ",
    company: "HCL",
    skills: ["SAP", "ERP Solutions"],
    role: "SAP Consultant",
    photo:
      "https://img.freepik.com/free-photo/close-up-excited-person-portrait_23-2151186646.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Arjun ",
    company: "Deloitte",
    skills: ["Data Science", "Python", "Machine Learning"],
    role: "Data Scientist",
    photo:
      "https://img.freepik.com/free-photo/smiley-man-posing-medium-shot_23-2149915893.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Nikita ",
    company: "TechNova Startup",
    skills: ["Full Stack", "Node.js", "MongoDB"],
    role: "Full Stack Developer",
    photo:
      "https://img.freepik.com/free-photo/young-girl-smiling-close-up_1187-1999.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Rahul ",
    company: "TCS",
    skills: ["Business Analysis", "Excel", "Power BI"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/RAJESH.png",
  },
  {
    name: "Sneha ",
    company: "Capgemini",
    skills: ["SAP MM"],
    role: "SAP MM Consultant",
    photo:
      "https://img.freepik.com/premium-photo/face-young-happy-woman-smiling-against-blue-background_251136-13530.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Vikram ",
    company: "Wipro",
    skills: ["Data Analytics", "SQL", "Tableau"],
    role: "Data Analyst",
    photo:
      "https://img.freepik.com/premium-photo/portrait-young-man_1048944-27791352.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Kavya ",
    company: "HCL",
    skills: ["Salesforce", "APEX", "Integration"],
    role: "Salesforce Developer",
    photo:
      "https://img.freepik.com/free-photo/portrait-beautiful-woman-wearing-traditional-sari-garment_23-2149565124.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Aditya",
    company: "InnoVision Startup",
    skills: ["Full Stack", "Python", "Django"],
    role: "Full Stack Developer",
    photo:
      "https://img.freepik.com/free-photo/close-up-excited-person-portrait_23-2151186643.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Pooja ",
    company: "TCS",
    skills: ["Data Science", "R", "Python"],
    role: "Data Scientist",
    photo:
      "https://img.freepik.com/premium-photo/young-woman-thinking-studio-portrait_53876-38973.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Manoj",
    company: "Capgemini",
    skills: ["Business Analysis", "Power BI", "Excel"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GUNASEKAR-.png",
  },
  {
    name: "Riya ",
    company: "Wipro",
    skills: ["SAP QM"],
    role: "Quality Engineer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Janani.png",
  },
  {
    name: "Siddharth ",
    company: "HCL",
    skills: ["SAP", "ERP Implementation"],
    role: "SAP Consultant",
    photo:
      "https://img.freepik.com/free-photo/closeup-young-hispanic-man-casuals-studio_662251-600.jpg?ga=GA1.1.92094253.1728639721&semt=ais_hybrid",
  },
  {
    name: "Meera ",
    company: "Innovative Minds Startup",
    skills: ["Full Stack", "React", "Node.js", "Express"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Nivetha.png",
  },
  {
    name: "Ankit ",
    company: "Deloitte",
    skills: ["Data Analytics", "Power BI", "SQL"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/MUTHU-KUMAR-.png",
  },
  {
    name: "Shreya",
    company: "TCS",
    skills: ["Business Analysis", "Market Research"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Vivek.png",
  },
  {
    name: "Rohan ",
    company: "Capgemini",
    skills: ["Salesforce", "Customization", "APEX"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/SUBASH.png",
  },
  {
    name: "Divya",
    company: "Wipro",
    skills: ["Data Science", "Deep Learning"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Chandru.png",
  },
  {
    name: "Ishan",
    company: "HCL",
    skills: ["Full Stack", "React", "Node.js"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/VENKATESH-.png",
  },
  {
    name: "Sanya ",
    company: "Deloitte",
    skills: ["Data Science", "Machine Learning"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Uma.png",
  },
  {
    name: "Arnav ",
    company: "InfyTech Startup",
    skills: ["Frontend Development", "HTML", "CSS", "JavaScript"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Kumar.png",
  },
  {
    name: "Ritik ",
    company: "Capgemini",
    skills: ["SAP", "ERP Management"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Venkatesh.png",
  },
  {
    name: "Pranav",
    company: "TCS",
    skills: ["Business Analysis", "Power BI", "Data Visualization"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/VINOTH.png",
  },
  {
    name: "Neha ",
    company: "Wipro",
    skills: ["Salesforce", "CRM Customization"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Sivagami.png",
  },
  {
    name: "Kunal ",
    company: "InnoEdge Startup",
    skills: ["Full Stack", "Python", "Flask"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/ASHOK-RAJ.png",
  },
  {
    name: "Aditi",
    company: "HCL",
    skills: ["Data Analytics", "Excel", "SQL"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Jayalakshmi.png",
  },
  {
    name: "Rohit",
    company: "Deloitte",
    skills: ["Data Science", "TensorFlow"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/RAGU-.png",
  },
  {
    name: "Sneha ",
    company: "TCS",
    skills: ["Frontend Development", "Angular"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Kaviya.png",
  },
  {
    name: "Abhay ",
    company: "Capgemini",
    skills: ["Business Analysis", "Market Research"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/MUTHU-KUMAR-.png",
  },
  {
    name: "Ira ",
    company: "Wipro",
    skills: ["SAP", "Integration"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Janani.png",
  },
  {
    name: "Rajesh ",
    company: "InnovateHub Startup",
    skills: ["Full Stack", "MERN Stack"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/VARUN-KRISHNAN.png",
  },
  {
    name: "Shanaya ",
    company: "HCL",
    skills: ["Data Analytics", "Tableau", "Power BI"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GAYANTHRI.png",
  },
  {
    name: "Aravind ",
    company: "Deloitte",
    skills: ["Data Science", "R", "Python"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/BASKAR-.png",
  },
  {
    name: "Tanya ",
    company: "TCS",
    skills: ["Frontend Development", "React", "CSS"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GUNASEKAR-.png",
  },
  {
    name: "Anuj ",
    company: "Capgemini",
    skills: ["Business Analysis", "Data Visualization"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/RAGUL-.png",
  },
  {
    name: "Megha ",
    company: "Wipro",
    skills: ["Salesforce", "CRM Workflows"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/MITHRAN-.png",
  },
  {
    name: "Rakesh ",
    company: "HCL",
    skills: ["SAP", "Process Automation"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/KABELASH-.png",
  },
  {
    name: "Asha ",
    company: "TechSpark Startup",
    skills: ["Full Stack", "Vue.js", "Node.js"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/LAKSHMIKANTHAN.png",
  },
  {
    name: "Vedant ",
    company: "TCS",
    skills: ["Data Analytics", "SQL"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/VARUN-KRISHNAN.png",
  },
  {
    name: "Ananya ",
    company: "Capgemini",
    skills: ["Frontend Development", "HTML", "Bootstrap"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GAYANTHRI.png",
  },
  {
    name: "Rahul ",
    company: "Wipro",
    skills: ["Business Analysis", "Power BI", "Excel"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/RAJAAJKUMAR.png",
  },
  {
    name: "Ishaan ",
    company: "Deloitte",
    skills: ["Data Science", "Neural Networks"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/PREAM-.png",
  },
  {
    name: "Nisha ",
    company: "HCL",
    skills: ["SAP", "ERP Implementation"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/LAKSHMIKANTHAN.png",
  },
  {
    name: "Prakash ",
    company: "BrightMind Startup",
    skills: ["Full Stack", "PHP", "MySQL"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/KABELASH-.png",
  },
  {
    name: "Ritika ",
    company: "TCS",
    skills: ["Data Science", "Python"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/LAKSHMI-VARAKAN.png",
  },
  {
    name: "Aryan",
    company: "Capgemini",
    skills: ["Frontend Development", "React"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/MITHRAN-.png",
  },
  {
    name: "Simran ",
    company: "Wipro",
    skills: ["Salesforce", "API Integrations"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/JOSEPH-.png",
  },
  {
    name: "Jayesh ",
    company: "HCL",
    skills: ["Business Analysis", "Excel"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/JAMES.png",
  },
  {
    name: "Akshay ",
    company: "InnovateX Startup",
    skills: ["Full Stack", "Django", "React"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/RAFEEQ-.png",
  },
  {
    name: "Neha ",
    company: "Deloitte",
    skills: ["Data Analytics", "Tableau"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/UMADEVI.png",
  },
  {
    name: "Vani ",
    company: "Capgemini",
    skills: ["SAP", "Data Integration"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/ESHANIKA.png",
  },
  {
    name: "Rohini ",
    company: "Wipro",
    skills: ["Data Science", "Big Data"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Angel.png",
  },
  {
    name: "Aditya ",
    company: "TechCraft Startup",
    skills: ["Frontend Development", "Angular", "CSS"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/KAVERI.png",
  },
  {
    name: "Tara",
    company: "TCS",
    skills: ["Business Analysis", "Market Analytics"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/KAVITHA.png",
  },
  {
    name: "Keshav ",
    company: "HCL",
    skills: ["Data Science", "AI/ML"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/ASHIQ-.png",
  },
  {
    name: "Shweta ",
    company: "Deloitte",
    skills: ["Full Stack", "React", "Express"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/CHANDIKA.png",
  },
  {
    name: "Kabir",
    company: "Wipro",
    skills: ["Data Science", "Machine Learning"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/ETHIRAJ-.png",
  },
  {
    name: "Tanvi",
    company: "HCL",
    skills: ["SAP", "ERP Solutions"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/ISWARYA.png",
  },
  {
    name: "Dev",
    company: "Deloitte",
    skills: ["Business Analysis", "Power BI"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/HARIHARAN.png",
  },
  {
    name: "Meghna",
    company: "TCS",
    skills: ["Frontend Development", "React", "JavaScript"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/POORANI.png",
  },
  {
    name: "Raghav",
    company: "Capgemini",
    skills: ["Full Stack", "Node.js", "MongoDB"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/OMKAR.png",
  },
  {
    name: "Anaya",
    company: "TechNova Startup",
    skills: ["Data Analytics", "SQL", "Tableau"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GEETHA-LAKSHMI.png",
  },
  {
    name: "Vivaan",
    company: "Wipro",
    skills: ["Salesforce", "CRM Integration"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/DINESH-.png",
  },
  {
    name: "Siddhi",
    company: "HCL",
    skills: ["Data Science", "Python"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GEETHA-LAKSHMI.png",
  },
  {
    name: "Aryan",
    company: "Deloitte",
    skills: ["Frontend Development", "HTML", "CSS", "Angular"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/MUBARAK-.png",
  },
  {
    name: "Avni",
    company: "InnoVision Startup",
    skills: ["Full Stack", "React", "Express", "MongoDB"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/OVIYA.png",
  },
  {
    name: "Vihaan",
    company: "TCS",
    skills: ["Data Science", "AI/ML"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/JAGADESH.png",
  },
  {
    name: "Keya",
    company: "Capgemini",
    skills: ["Business Analysis", "Excel", "Market Research"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/BALAKARTHICK.png",
  },
  {
    name: "Reyansh",
    company: "Wipro",
    skills: ["Frontend Development", "JavaScript", "Vue.js"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/VIJAY.png",
  },
  {
    name: "Navya",
    company: "InnovateX Startup",
    skills: ["Full Stack", "Python", "Flask"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/ISWARYA.png",
  },
  {
    name: "Ayaan",
    company: "HCL",
    skills: ["SAP", "Process Automation"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/PRADEEP-NAIR.png",
  },
  {
    name: "Esha",
    company: "Deloitte",
    skills: ["Data Analytics", "Power BI", "Tableau"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/OVIYA.png",
  },
  {
    name: "Ivaan",
    company: "TechSpark Startup",
    skills: ["Full Stack", "Vue.js", "Node.js"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/JEGAN.png",
  },
  {
    name: "Samaira",
    company: "TCS",
    skills: ["Frontend Development", "HTML", "CSS", "Bootstrap"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GEETHA-LAKSHMI.png",
  },
  {
    name: "Prisha",
    company: "Capgemini",
    skills: ["Salesforce", "API Integrations"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Vishnu.png",
  },
  {
    name: "Arhaan",
    company: "Wipro",
    skills: ["Data Science", "Neural Networks", "Big Data"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Yokesh.png",
  },
  {
    name: "Diya",
    company: "BrightMind Startup",
    skills: ["Full Stack", "MERN Stack"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Uma.png",
  },
  {
    name: "Shaurya",
    company: "HCL",
    skills: ["Data Analytics", "Excel", "SQL"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Janani.png",
  },
  {
    name: "Mihika",
    company: "Deloitte",
    skills: ["Business Analysis", "Market Analytics"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GAYANTHRI.png",
  },
  {
    name: "Arjun",
    company: "InnoEdge Startup",
    skills: ["Full Stack", "Python", "Django"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/MITHRAN-.png",
  },
  {
    name: "Nivaan",
    company: "Capgemini",
    skills: ["Full Stack", "React", "Node.js"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/JAVESH-.png",
  },
  {
    name: "Tara",
    company: "Wipro",
    skills: ["Data Science", "Machine Learning", "Python"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/SATHEESH.png",
  },
  {
    name: "Kiaan",
    company: "HCL",
    skills: ["SAP", "ERP Implementation"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/FAIZAN-.png",
  },
  {
    name: "Ahaana",
    company: "Deloitte",
    skills: ["Business Analysis", "Power BI", "Excel"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Venkatesh.png",
  },
  {
    name: "Ishaan",
    company: "TCS",
    skills: ["Frontend Development", "React", "CSS"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/SATHEESH.png",
  },
  {
    name: "Pari",
    company: "BrightVision Startup",
    skills: ["Data Analytics", "SQL", "Tableau"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/LAKSHMI-VARAKAN.png",
  },
  {
    name: "Agastya",
    company: "TechNova Startup",
    skills: ["Salesforce", "CRM Integration"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/JAVESH-.png",
  },
  {
    name: "Mira",
    company: "Capgemini",
    skills: ["Data Science", "AI", "Deep Learning"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/ESHANIKA.png",
  },
  {
    name: "Rudra",
    company: "Wipro",
    skills: ["Frontend Development", "Angular", "TypeScript"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/KAMAL.png",
  },
  {
    name: "Anaaya",
    company: "InnoTech Startup",
    skills: ["Full Stack", "Express", "MongoDB"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Sivagami.png",
  },
  {
    name: "Yuvraj",
    company: "HCL",
    skills: ["Business Analysis", "Market Research"],
    role: "Business Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Vikram.png",
  },
  {
    name: "Kiara",
    company: "Deloitte",
    skills: ["Data Science", "Big Data", "Python"],
    role: "Data Scientist",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/RAJU.png",
  },
  {
    name: "Aarush",
    company: "TCS",
    skills: ["Frontend Development", "HTML", "JavaScript"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/VINOTH.png",
  },
  {
    name: "Zara",
    company: "Wipro",
    skills: ["SAP", "Process Automation"],
    role: "SAP Consultant",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/RAGU-.png",
  },
  {
    name: "Atharv",
    company: "Capgemini",
    skills: ["Data Analytics", "Power BI", "SQL"],
    role: "Data Analyst",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/GOPINATH-.png",
  },
  {
    name: "Leah",
    company: "InnovateHub Startup",
    skills: ["Full Stack", "PHP", "MySQL"],
    role: "Full Stack Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/SIVANESH.png",
  },
  {
    name: "Advik",
    company: "HCL",
    skills: ["Salesforce", "CRM Development"],
    role: "Salesforce Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/VARUN-KRISHNAN.png",
  },
  {
    name: "Vedika",
    company: "BrightMind Startup",
    skills: ["Frontend Development", "React", "Bootstrap"],
    role: "Frontend Developer",
    photo: "https://www.acte.in/wp-content/uploads/2024/05/Janani.png",
  },
];

export default placedStudentsData;
