import React, { useState } from "react";
import "../Css/LearningOutcome.css";
import H1 from '../assets/H1.webp'
import H2 from '../assets/H2.webp'
import H3 from '../assets/H3.webp'

const LearningOutcome = () => {
  return (
    <div className="learning-outcome-section">
      <h2 className="section-heading">LEARNING OUTCOME

      </h2>
      <div className="card-container">
        <OutcomeCard
          imgSrc= {H1}
          title="Industry Curriculum"
          description="Unlock your potential with our industry-standard curriculum and expert trainers. Gain the skills and knowledge you need to excel in your field."
        />
          <OutcomeCard
          imgSrc= {H2}
          title="Global Certification Guidance"
          description="Achieve global certification with confidence & ease.Our guidance program ensures you have skills to pass your exam."
        />
        <OutcomeCard
          imgSrc= {H3}
          title="Projects"
          description="Experience real-world projects and gain practical skills with our comprehensive training programs. Our hands-on approach ensures that you’ll be ready to tackle any challenge in your field."
        />
      
      </div>
    </div>
  );
};

const OutcomeCard = ({ imgSrc, title, description }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`outcome-card ${hovered ? "hovered" : ""}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="card-img-wrapper">
        <img src={imgSrc} alt={title} className={`card-img ${hovered ? "color" : ""}`} />
      </div>
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        {hovered && <p className="card-description">{description}</p>}
      </div>
    </div>
  );
};

export default LearningOutcome;
