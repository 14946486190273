import React from "react";
import { useForm } from "react-hook-form";
import IMG from "../assets/y.jpg"

const DialogContact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset, // Used to reset form values
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "353dd854-1ca9-4730-8291-1ead344c700d",
          name: data.name,
          mobile: data.mobile,
          trainingMode: data.trainingMode,
          message: data.message,
          subject: "Placement Training Form Submission",
          from_name: "ITAccurate",
        }),
      });

      const result = await response.json();

      if (result.success) {
        alert("Message sent successfully!");
        reset(); // Clear the form
      } else {
        console.error("Submission failed:", result.message);
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row shadow-lg overflow-hidden w-full">
      {/* Left Section */}
      <div className="bg-[#007BFF] text-white p-6 flex flex-col justify-center lg:w-1/3 lg:block hidden">
        <h3 className="text-xl font-bold mb-2">
          Up-Skill your Career with the world's
        </h3>
        <p className="font-semibold text-yellow-400">
          Effective Online & Classroom Training
        </p>
        <p className="mt-2">
          Trusted by <span className="font-bold">3,50,850+</span> Satisfied Learners &{" "}
          <span className="font-bold">123+</span> Corporates
        </p>
        <div className="mt-4">
          <img
            src={IMG}
            alt="Training Illustration"
            className="w-full"
          />
        </div>
      </div>

      {/* Form Section */}
      <div className="p-6 lg:w-2/3 w-full">
        <h2 className="text-xl font-bold mb-4">Placement Training Form</h2>
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          {/* Name Input */}
          <div>
            <input
              type="text"
              placeholder="Your Name"
              {...register("name", {
                required: "Name is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters long",
                },
              })}
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
          </div>

          {/* Mobile Input */}
          <div>
            <input
              type="tel"
              placeholder="Mobile Number"
              {...register("mobile", {
                required: "Mobile number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Enter a valid 10-digit mobile number",
                },
              })}
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile.message}</p>}
          </div>

          {/* Training Mode Input */}
          <div>
            <input
              type="text"
              placeholder="Mode of Training"
              {...register("trainingMode", {
                required: "Mode of training is required",
                minLength: {
                  value: 5,
                  message: "Training mode must be at least 5 characters long",
                },
              })}
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.trainingMode && (
              <p className="text-red-500 text-sm">{errors.trainingMode.message}</p>
            )}
          </div>

          {/* Message Input */}
          <div>
            <textarea
              placeholder="Your Message"
              {...register("message", {
                required: "Message is required",
                minLength: {
                  value: 10,
                  message: "Message must be at least 10 characters long",
                },
              })}
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            ></textarea>
            {errors.message && <p className="text-red-500 text-sm">{errors.message.message}</p>}
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DialogContact;
