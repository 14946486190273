import React from "react";
import { Menus } from "./Navbar/utils";
import Logo from "../assets/Logo.webp";
import DesktopMenu from "./Navbar/DesktopMenu";
import MobMenu from "./Navbar/MobMenu";

const Navbar = () => {
    return (
        <header
            id="navbar"
            className="h-16 fixed top-0 left-0 right-0 z-50 text-white shadow-lg backdrop-blur-xl bg-purple-800/20"
        >
            <nav className="flex items-center justify-between px-4 md:px-8 max-w-7xl mx-auto">
                {/* Logo Section */}
                <div className="flex mt-2 items-center">
                    <a href="/" className="block">
                        <img
                            src={Logo}
                            alt="Netket Technologies Logo"
                            className="w-10"
                        />
                    </a>
                    <a href="/" className="text-xl mr-4">Sapalogy Training</a>
                </div>

                {/* Desktop Menu */}  
                <ul className="hidden md:flex mt-2 items-center gap-x-6">
                    {Menus.map((menu) => (
                        <DesktopMenu menu={menu} key={menu.name} />
                    ))}

                </ul>

                {/* Mobile Menu */}
                <div className="md:hidden">
                    <MobMenu Menus={Menus} />
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
