import React from 'react'
import Slider from "react-slick"; // Ensure react-slick is installed
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const RegularSlider = ({ sliderSettings, images }) => {
  return (
    <Slider {...sliderSettings}>
    {images.map((image, index) => (
      <div key={index} className="slider-item">
        <img src={image.Image} alt={image.alt} className="slider-image" />
      </div>
    ))}
  </Slider>
  )
}

export default RegularSlider