import React from 'react';
import Marquee from 'react-fast-marquee';
import '../Css/CustomMarquee.css';
import { useModal } from '../context/ModelContext';


const UpdatedMarquee = () => {
  const { openModal } = useModal();

  const students = [
    { 
      name: 'Manasi', 
      company: 'TCS', 
      image: 'https://i.postimg.cc/qMh0T6h6/Whats-App-Image-2024-11-09-at-4-24-26-PM.jpg', 
      logo: 'https://www.pc-tablet.co.in/wp-content/uploads/2024/04/Tata-Consultancy-Services-TCS.png', 
      linkedin: '#' 
    },
    { 
      name: 'Abhijeet', 
      company: 'Transrail Lightning LTD', 
      image: 'https://i.postimg.cc/0jWxZ8Jm/abhijeet-kale-image.jpg', 
      logo: 'https://transrail.in/images/logo.png', 
      linkedin: '#' 
    },
    { 
      name: 'Mayuri', 
      company: 'TCS', 
      image: 'https://i.postimg.cc/v84QpJL3/new.jpg', 
      logo: 'https://www.pc-tablet.co.in/wp-content/uploads/2024/04/Tata-Consultancy-Services-TCS.png', 
      linkedin: '#' // Default placeholder for missing LinkedIn URLs
    },
    { 
      name: 'Yogita', 
      company: 'HCL', 
      image: 'https://i.postimg.cc/7hNhf7sw/yogita-gaikwad.jpg', 
      logo: 'https://download.logo.wine/logo/HCL_Technologies/HCL_Technologies-Logo.wine.png', 
      linkedin: '#' 
    },
    { 
      name: 'Jasmine', 
      company: 'Talentrise Technokrate Pvt Ltd.', 
      image: 'https://i.postimg.cc/258n7jRz/Whats-App-Image-2024-11-16-at-3-12-42-PM.jpg', 
      logo: 'https://technokrate.com/img/Techknokrate%20logo%202.png', // Placeholder for company logo
      linkedin: '#' 
    },
    { 
      name: 'Yash', 
      company: 'JBL', 
      image: 'https://i.postimg.cc/9XkS6XG0/placement.png', 
      logo: 'https://in.jbl.com/on/demandware.static/-/Library-Sites-SharedLibrary-JB/default/dw7c092073/images/jbl-logo.svg', 
      linkedin: '#' 
    },
    { 
      name: 'Apurva', 
      company: 'HCL', 
      image: 'https://i.postimg.cc/ncVHVGrr/placement-1.png', 
      logo: 'https://download.logo.wine/logo/HCL_Technologies/HCL_Technologies-Logo.wine.png', 
      linkedin: '#' 
    },
    { 
      name: 'Aishwarya', 
      company: 'Lupin Pharma', 
      image: 'https://i.postimg.cc/C5J25D5p/placement-2.png', 
      logo: 'https://www.sap.com/dam/application/shared/logos/customer/h-q/lupin-customer-logo.png', 
      linkedin: '#' 
    }
  ];


  const students1 = [
    { 
      name: 'Anshul', 
      company: 'TCS', 
      image: 'https://i.postimg.cc/XJQf07zq/image.png', 
      logo: 'https://i.postimg.cc/KYVK7kwn/image.png', 
      linkedin: '#' 
    },
    { 
      name: 'Harshal', 
      company: 'Transrail Lightning LTD', 
      image: 'https://i.postimg.cc/yYJbnwDc/image.png', 
      logo: 'https://i.postimg.cc/7Zkj6KLz/image.png', 
      linkedin: '#' 
    },
    { 
      name: 'Hardik', 
      company: 'TCS', 
      image: 'https://i.postimg.cc/rz2kJcNy/image.png', 
      logo: 'https://i.postimg.cc/7Zkj6KLz/image.png', 
      linkedin: '#' // Default placeholder for missing LinkedIn URLs
    },
    { 
      name: 'Rishabh', 
      company: 'HCL', 
      image: 'https://i.postimg.cc/m2Vj74NT/image.png', 
      logo: 'https://i.postimg.cc/Df6LMgWg/image.png', 
      linkedin: '#' 
    },
    { 
      name: 'Nikhil', 
      company: 'Talentrise Technokrate Pvt Ltd.', 
      image: 'https://i.postimg.cc/ZYDbDBLg/image.png', 
      logo: 'https://i.postimg.cc/jjL0fvpS/image.png', // Placeholder for company logo
      linkedin: '#' 
    },
    { 
      name: 'Shubham', 
      company: 'JBL', 
      image: 'https://i.postimg.cc/3wLWZLf4/image.png', 
      logo: 'https://i.postimg.cc/fb3Q6BT0/image.png', 
      linkedin: '#' 
    },
    { 
      name: 'Mukul', 
      company: 'HCL', 
      image: 'https://i.postimg.cc/1tmW6znc/image.png', 
      logo: 'https://i.postimg.cc/Df6LMgWg/image.png', 
      linkedin: '#' 
    },
    { 
      name: 'Varsha', 
      company: 'Lupin Pharma', 
      image: 'https://i.postimg.cc/PrGGwXq0/image.png', 
      logo: 'https://i.postimg.cc/MKhGXJkG/image.png', 
      linkedin: '#' 
    }
  ];
  return (
    <div className="updated-marquee-container">
      <h2 className="updated-marquee-heading">
        Have Cracked Their Dream Job in Top MNC Companies
      </h2>

      <Marquee className="updated-marquee" speed={30}>
        {students.map((student, index) => (
          <div className="updated-marquee-card" key={index}>
            <a href={student.linkedin} target="_blank" rel="noopener noreferrer" className="updated-linkedin-icon">
              <img src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg" alt="LinkedIn" />
            </a>
            <img src={student.image} alt={student.name} className="updated-profile-image" />
            <p className="updated-name">{student.name}</p>
            <img src={student.logo} alt={student.company} className="updated-company-logo" />
          </div>
        ))}
      </Marquee>

      <Marquee className="updated-fast-marquee" speed={70} direction="right">
        {students1.map((student, index) => (
          <div className="updated-marquee-card" key={index}>
            <a href={student.linkedin} target="_blank" rel="noopener noreferrer" className="updated-linkedin-icon">
              <img src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg" alt="LinkedIn" />
            </a>
            <img src={student.image} alt={student.name} className="updated-profile-image" />
            <p className="updated-name">{student.name}</p>
            <img src={student.logo} alt={student.company} className="updated-company-logo" />
          </div>
        ))}
      </Marquee>

      <div className="updated-button-container">
        <button className="updated-custom-button"><a href="/contact">
          Book Your Free Trial, Now</a>
        </button>
        <a href="/placed">
          <button className="updated-custom-button">Get Our Placement Stats</button>
        </a>
      </div>
    </div>
  );
};

export default UpdatedMarquee;
