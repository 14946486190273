import React from 'react'
import Hero from '../Components/Hero'
import Cart from '../Components/Cart'
import WhyUs from '../Components/WhyUs'
import LearningOutcome from '../Components/LearningOutcome'
import Slider from '../Components/Slider'
import WhatMakesUsDifferent from '../Components/WhatMakesUsDifferent'
import ContactForm from './Contact'
import AlumniMarquee from '../Components/AlumniMarquee'
import FeesDetails from '../Components/FeesDetails'
import Marq from '../Components/CustomMarquee';
import TrainerDetails from '../Components/TrainerDetails';
import Brand from '../Components/Brands'
import JobPreparation from '../Components/JobPreparation'
import RatingStrip from '../Components/RatingStrip'
import SalaryHikeSection from '../Components/SalaryHike'

const Home = () => {
  return (
    <div>
      <Hero/>
      <RatingStrip/>
      <Cart/>
      <Brand/>
      <WhatMakesUsDifferent/>
      <Marq />
      <WhyUs/>
      <SalaryHikeSection />
      <LearningOutcome/>
      <TrainerDetails/>
      <AlumniMarquee/>
      <FeesDetails/>
      <JobPreparation/>
      <Slider/>
      <ContactForm/>
    </div>
  )
}

export default Home
