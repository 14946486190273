import React, { useEffect, useRef, useState } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'

import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
// project slider image
import dev1 from '../../assets/Projects/Salesforce/1.png';
import dev2 from '../../assets/Projects/Salesforce/2.png';
import dev3 from '../../assets/Projects/Salesforce/3.png';
import dev4 from '../../assets/Projects/Salesforce/4.png';
import dev5 from '../../assets/Projects/Salesforce/5.png';
import dev6 from '../../assets/Projects/Salesforce/6.png';
import dev7 from '../../assets/Projects/Salesforce/7.png';
import dev8 from '../../assets/Projects/Salesforce/8.png';
import dev9 from '../../assets/Projects/Salesforce/9.png';
import dev10 from '../../assets/Projects/Salesforce/10.png';

import rev1 from '../../assets/Reviews/Salesforce/sf_rev1.png';
import rev2 from '../../assets/Reviews/Salesforce/sf_rev2.png';
import rev3 from '../../assets/Reviews/Salesforce/sf_rev3.png';
import rev4 from '../../assets/Reviews/Salesforce/sf_rev4.png';
import rev5 from '../../assets/Reviews/Salesforce/sf_rev5.png';
import RegularSlider from '../../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle,faStar,faProjectDiagram,faLaptop } from '@fortawesome/free-solid-svg-icons';
import JobPreparation from '../../Components/JobPreparation';
import CustomMarquee from '../../Components/CustomMarquee';
import CourseOpportunities from '../../Components/CourseOpportunities'
import BatchesPage from '../../Components/Batches'
import MarqueeComponent from '../../Components/MarqueeComponent'

const BAHR = () => {

  const roadmapData = [
    {
      title: 'Google Forms and Sheets',
      points: [
        'Creating, running, and analysing surveys',
        'Creating, running, and analysing tests',
        'Creating information gathering forms',
        'Using Google Sheets, graphs, and Pivot tables',
      ],
    },
    {
      title: 'Employee Engagement',
      points: [
        'Understanding the concept',
        'Attributes of Engagement',
        'Employee Engagement Survey',
        'Analysing the Survey and related HR actions',
        'Examples of Employee Engagement Activities',
      ],
    },
    {
      title: 'Competency Mapping',
      points: [
        'Understanding Competencies',
        'Iceberg Model',
        'Competency Mapping process',
        'Methods of Competency Mapping',
        'Demonstration of Behavioural Event Interview',
        'Proficiency Levels in Competencies',
        'Competency dictionaries',
        'Linking Competency Framework to all HR functions',
      ],
    },
    {
      title: 'Recruitment and Selection',
      points: [
        'Difference between Recruitment and Selection',
        'Steps in Recruitment',
        'Internal Recruitment methods',
        'External Recruitment methods',
        'Steps in Selection',
        'Screening and Interviews',
        'Aptitude, practical, and Psychometric testing',
        'Live demonstration of Psychometric testing',
        'Naukri.com job posting',
        'Naukri.com Resdex',
        'Naukri.com Boolean search',
        'Indeed job posting',
        'LinkedIn job posting',
        'Lateral Hiring',
        'Head Hunting',
      ],
    },
    {
      title: 'Induction and Orientation (On-boarding)',
      points: [
        'Difference between Induction and Orientation',
        'Steps in Induction process',
        'Steps in Induction Orientation',
        'Checklist and Assets issue record',
        'Making Picture Organization chart',
        'Statutory and other documentation',
        'Understanding POSH (Prevention of Sexual Harassment)',
        'Employee Manual',
        'Safety training and Mock drills',
        'Induction and Orientation effectiveness test',
      ],
    },
    {
      title: 'Learning and Development',
      points: [
        'Difference between Learning and Development',
        'Aspects of Learning from learner\'s perspective',
        'Aspects of Learning from trainer\'s perspective',
        'Training Need Identification (TNA/TNI)',
        'Steps in Learning',
        'Steps in Development',
        'Training Feedback',
        'Application of Learning',
        'Effectiveness of Learning and Development',
        'Succession Planning and Succession Development',
        'Training Plan and Training Calendar',
      ],
    },
    {
      title: 'Power BI',
      points: [
        'Introduction to Power BI',
        'Creating and Sharing Dashboards',
        'Data Transformation and Cleaning',
        'Using Power Query and DAX',
        'Power BI Reports and Visualizations',
        'Connecting to Data Sources',
      ],
    },
    {
      title: 'Tableau',
      points: [
        'Introduction to Tableau',
        'Creating Visualizations and Dashboards',
        'Working with Data in Tableau',
        'Filtering and Sorting Data',
        'Tableau Calculations',
        'Data Blending and Joining',
      ],
    },
    {
      title: 'Advanced Excel',
      points: [
        'Advanced Formulas and Functions',
        'Data Analysis with PivotTables and PivotCharts',
        'Conditional Formatting and Data Validation',
        'Macros and VBA',
        'Power Query in Excel',
        'Excel Dashboarding',
      ],
    },
    {
      title: 'Statistics',
      points: [
        'Descriptive Statistics',
        'Probability and Probability Distributions',
        'Hypothesis Testing',
        'Correlation and Regression Analysis',
        'Sampling Methods',
        'Data Interpretation',
      ],
    },
    {
      title: 'SharePoint',
      points: [
        'Introduction to SharePoint',
        'Creating and Managing SharePoint Sites',
        'Document Management in SharePoint',
        'SharePoint Permissions and Security',
        'Collaborative Features in SharePoint',
        'Using Lists and Libraries in SharePoint',
      ],
    },
  ];
  
  const syllabus = [
    {
      title: 'Google Forms and Google Sheets (6 hours)',
      subpoints: [
        'Creating, running and analysing surveys',
        'Creating, running and analysing tests',
        'Creating information gathering forms',
        'Using Google Sheets, graphs and Pivot tables',
      ],
    },
    {
      title: 'Employee Engagement (4 hours)',
      subpoints: [
        'Understanding the concept',
        'Attributes of Engagement',
        'Employee Engagement Survey',
        'Analysing the Survey and related HR actions',
        'Examples of Employee Engagement Activities',
      ],
    },
    {
      title: 'Competency Mapping (4 hours)',
      subpoints: [
        'Understanding Competencies',
        'Iceberg Model',
        'Competency Mapping process',
        'Methods of Competency Mapping',
        'Demonstration of Behavioural Event Interview',
        'Proficiency Levels in Competencies',
        'Competency dictionaries',
        'Linking Competency Framework to all HR functions',
      ],
    },
    {
      title: 'Recruitment and Selection (6 hours)',
      subpoints: [
        'Difference between Recruitment and Selection',
        'Steps in Recruitment',
        'Internal Recruitment methods',
        'External Recruitment methods',
        'Steps in Selection',
        'Screening and Interviews',
        'Aptitude, practical and Psychometric testing',
        'Live demonstration of Psychometric testing',
        'Naukri.com job posting',
        'Naukri.com Resdex',
        'Naukri.com Boolean search',
        'Indeed job posting',
        'LinkedIn job posting',
        'Lateral Hiring',
        'Head Hunting',
      ],
    },
    {
      title: 'Induction and Orientation (On-boarding) (4 hours)',
      subpoints: [
        'Difference between Induction and Orientation',
        'Steps in Induction process',
        'Steps in Orientation process',
        'Checklist and Assets issue record',
        'Making Picture Organization chart',
        'Statutory and other documentation',
        'Understanding POSH (Prevention of Sexual Harassment)',
        'Employee Manual',
        'Safety training and Mock drills',
        'Induction and Orientation effectiveness test',
      ],
    },
    {
      title: 'Learning and Development (4 hours)',
      subpoints: [
        'Difference between Learning and Development',
        "Aspects of Learning from learner's perspective",
        "Aspects of Learning from trainer's perspective",
        'Training Need Identification (TNA/TNI)',
        'Steps in Learning',
        'Steps in Development',
        'Training Feedback',
        'Application of Learning',
        'Effectiveness of Learning and Development',
        'Succession Planning and Succession Development',
        'Training Plan and Training Calendar',
      ],
    },
    {
      title: 'Power BI (4 hours)',
      subpoints: [
        'Introduction to Power BI',
        'Creating and Sharing Dashboards',
        'Data Transformation and Cleaning',
        'Using Power Query and DAX',
        'Power BI Reports and Visualizations',
        'Connecting to Data Sources',
      ],
    },
    {
      title: 'Tableau (4 hours)',
      subpoints: [
        'Introduction to Tableau',
        'Creating Visualizations and Dashboards',
        'Working with Data in Tableau',
        'Filtering and Sorting Data',
        'Tableau Calculations',
        'Data Blending and Joining',
      ],
    },
    {
      title: 'Advanced Excel (4 hours)',
      subpoints: [
        'Advanced Formulas and Functions',
        'Data Analysis with PivotTables and PivotCharts',
        'Conditional Formatting and Data Validation',
        'Macros and VBA',
        'Power Query in Excel',
        'Excel Dashboarding',
      ],
    },
    {
      title: 'Statistics (4 hours)',
      subpoints: [
        'Descriptive Statistics',
        'Probability and Probability Distributions',
        'Hypothesis Testing',
        'Correlation and Regression Analysis',
        'Sampling Methods',
        'Data Interpretation',
      ],
    },
    {
      title: 'SharePoint (4 hours)',
      subpoints: [
        'Introduction to SharePoint',
        'Creating and Managing SharePoint Sites',
        'Document Management in SharePoint',
        'SharePoint Permissions and Security',
        'Collaborative Features in SharePoint',
        'Using Lists and Libraries in SharePoint',
      ],
    },
  ];
  
  
  

  const keyFeatures = [
    "50+ Hours of Instructor-Led Training",
    "Certification Preparation and Guidance",
    "Dedicated Mentor Support",
    "Professional Resume Editing Services",
    "52 Hours of Hands-On Projects and Exercises",
    "100% Job-Oriented Training Program",
    "69 Hours of Self-Paced Learning Videos",
    "Comprehensive Job Assistance",
    "Free Demo Classes Available",
    "Budget-Friendly Fee Structure",
    "Flexible Learning Schedule",
    "Successfully Completed 1482+ Batches",
    "Real-World Case Studies in Business and HR Analytics",
    "Access to Analytics Tools like Power BI, Tableau, and Excel",
    "Focus on Practical Applications and Industry Insights",
    "Mock Interviews and Career Preparation Workshops",
    "Expert Guidance on Data Visualization Techniques",
    "Interactive Sessions with Industry Professionals",
    "Post-Training Support for Job Placement and Growth",
  ];
  

  const faqs = [
    {
      question: "What is an HR Business Analyst?",
      answer: "An HR Business Analyst is a professional who analyzes human resources data and processes to improve organizational performance. They work closely with both the HR team and business stakeholders to ensure that HR strategies align with business goals, focusing on areas such as workforce planning, recruitment, retention, and performance management.",
    },
    {
      question: "What skills are essential for an HR Business Analyst?",
      answer: "Key skills for an HR Business Analyst include proficiency in data analysis tools like Excel, Power BI, and Tableau, a deep understanding of HR processes (recruitment, employee engagement, retention), strong problem-solving abilities, business strategy awareness, and effective communication skills to present insights clearly.",
    },
    {
      question: "What tools are used by HR Business Analysts?",
      answer: "HR Business Analysts commonly use tools such as Excel for data analysis, Power BI and Tableau for data visualization, SQL for querying databases, and HR platforms like SAP SuccessFactors or Oracle HCM for workforce management and analytics.",
    },
    {
      question: "How does an HR Business Analyst contribute to organizational success?",
      answer: "HR Business Analysts help organizations by providing data-driven insights into employee performance, turnover rates, engagement levels, and other key HR metrics. They identify trends, improve HR processes, and suggest actionable strategies to align HR practices with overall business objectives, leading to better decision-making and enhanced organizational efficiency.",
    },
    {
      question: "What are the main responsibilities of an HR Business Analyst?",
      answer: "The main responsibilities include analyzing workforce data to identify trends, supporting HR in decision-making for recruitment and retention, improving HR processes, creating reports and dashboards for business stakeholders, and aligning HR strategies with organizational goals. They also work on projects that require optimizing HR systems and ensuring data accuracy.",
    },
    {
      question: "How do HR Business Analysts use data in their role?",
      answer: "HR Business Analysts use data to identify patterns in employee behavior, turnover rates, recruitment success, and engagement levels. They analyze this data to provide actionable insights for improving HR processes, designing better HR policies, and aligning workforce strategies with business objectives.",
    },
    {
      question: "What qualifications are required to become an HR Business Analyst?",
      answer: "While a background in HR or business analysis is helpful, key qualifications include expertise in data analysis, proficiency in relevant tools (Excel, Power BI, Tableau), and knowledge of HR practices. Certifications in HR Analytics, business analysis, or data analytics can be advantageous for a successful career in this field.",
    },
    {
      question: "How can I transition into an HR Business Analyst role?",
      answer: "To transition into an HR Business Analyst role, start by gaining experience in HR functions and developing strong data analysis skills. Learn tools like Excel, Power BI, or Tableau. You may also consider completing courses in HR analytics, business analysis, or data science. Gaining practical experience through internships or projects will help as well.",
    },
    {
      question: "What challenges does an HR Business Analyst face?",
      answer: "HR Business Analysts may face challenges such as managing large volumes of employee data, ensuring data accuracy, aligning HR insights with business strategies, and addressing complex HR issues like employee engagement or retention. They also have to deal with organizational resistance to change when suggesting new HR processes.",
    },
    {
      question: "What is the career outlook for HR Business Analysts?",
      answer: "The career outlook for HR Business Analysts is promising, as organizations increasingly recognize the value of data-driven decision-making in HR. The demand for professionals who can combine HR expertise with analytical skills is growing, particularly in companies looking to optimize their HR functions and improve employee performance.",
    },
  ];
  

  const whatsData = [
    {
      title: 'What is an HR Business Analyst?',
    points: [
      'An HR Business Analyst bridges the gap between human resources and business objectives by using data to inform strategic decisions.',
      'HR Business Analysts analyze HR data, workflows, and business processes to optimize performance and improve efficiency.',
      'They work closely with HR teams and leadership to identify organizational needs, align HR strategies with business goals, and drive process improvements.',
      'Key tools for HR Business Analysts include Power BI, Excel, Tableau, SQL, and HR software like SAP SuccessFactors, Workday, and Oracle HRMS.',
      'Professionals in this role need strong analytical, communication, and problem-solving skills, along with a solid understanding of HR practices and business strategies.',
      'Aspiring HR Business Analysts should focus on building expertise in both HR functions and data analysis. Enroll in Sapalogy’s courses for practical knowledge and hands-on experience.',
    ],
    },
  ];
  

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
    { Image: dev9, alt: "Award 8" },
    { Image: dev10, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },

  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
  <h1>Sapalogy - Best HR Business Analyst Training in Nagpur</h1>
  <div className="stars-section">
    {[...Array(5)].map((_, index) => (
      <FontAwesomeIcon
        key={index}
        icon={faStar}
        className="star-icon"
      />
    ))}
  </div>
  <div className="ratings-info">
    <span className="rating">(4.8)</span>
    <span className="separator">|</span>
    <span className="ratings-count">7229 Ratings</span>
    <span className="separator">|</span>
    <span className="learners-count">6000 Learners</span>
  </div>
  <p>
  Sapalogy’s HR Business Analyst Training Program offers the best HR Analytics and Business Analytics training in Nagpur, designed for both freshers and experienced candidates. With expert mentorship and 100% job placement assistance, our program covers all essential topics, ensuring students gain real-world, hands-on experience.
</p>
<p>
  Our job-oriented training program equips learners not only with certifications but also with the knowledge and skills of professionals with 10+ years of experience. We prioritize your time, delivering comprehensive training in just 2 months.
</p>


  <h3>Key features of our training include:</h3>
  <ul className="points-list">
    <li><FontAwesomeIcon icon={faFileAlt} /> Professional resume preparation</li>
    <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview preparation and practice</li>
    <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship program</li>
    <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job assistance guaranteed</li>
    <li><FontAwesomeIcon icon={faLaptop} /> Access to analytics tools for hands-on practice</li>
    <li><FontAwesomeIcon icon={faProjectDiagram} /> Real-time project exposure</li>
  </ul>
</div>



        <PageForm />
      </div>

{/*     
      <div className="video-section">
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/syzh1IxEaak?rel=0&modestbranding=1&loop=1&playlist=syzh1IxEaak"
          title="DevOps Video"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="responsive-iframe"
        ></iframe>
      </div>
    </div> */}
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn HR Business Analyst " roadmapData={roadmapData} />
      <Syllabus heading="HR Business Analyst Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="HR Business Analyst" keyFeatures={keyFeatures} />

      <BatchesPage Batches={BatchesPage} />

<JobPreparation/>

      <section className="devops-certification-section">
        <h2 className="certification-heading">HR Business Analyst Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
          <ul>
  <li>
    Sapalogy training certification will serve as proof that the courses were completed by Sapalogy, showcasing your proficiency in HR Business Analyst.
  </li>
  <li>
    The Business Analytics for HR certification offered by Sapalogy will equip you with valuable skills, making you more competitive in the HR analytics job market.
  </li>
  <li>
    Sapalogy's HR Business Analyst training institute in Nagpur provides comprehensive guidance for your global certifications, ensuring a 100% passing guarantee in examinations such as Business Analytics for HR Certification, HR Analytics Certification, and various other global exams.
  </li>
</ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>





      <Faq Faqs={faqs} />

    

<CustomMarquee/>
    
 
      <CourseOpportunities pageName="HR_BA" />
      <MarqueeComponent />	
      <Contact />

    </div>
  );
};

export default BAHR;