import React from "react";
import "../Css/SubscribeDemo.css";
const SubscribeDemo = () => {


  return (
    <div className="subscribe-demo-card">
      <div className="subscribe-card-content">
        {/* Title Section */}
        <div className="subscribe-text">
          <h2 className="font-semibold">Contact Now for a Free Demo&nbsp;
            <span className="relative ">
              Get Placed.
              <span
                className="absolute left-0 bottom-0 h-[3px] w-full bg-purple-400"
                style={{ transform: "translateY(4px)" }}
              />
            </span>

          </h2>
          <p>
          Contact for a free demo is the first step toward building a successful career. Experience the course firsthand, explore essential industry tools, and grasp key concepts. Engage with expert trainers, ask your questions, and get a feel for the teaching approach to ensure you receive the best training possible.
          </p>
          <p>
          Additionally, the demo helps you evaluate your current skill level and identify areas for improvement, providing a clear path to job readiness. It builds your confidence and lays the foundation for success in your placement journey
          </p>
        </div>

        {/* Benefits Section */}
        <div className="benefits-grid">
          <div className="benefit-card">
            <i className="fas fa-tools"></i>
            <p>Practical Learning with Hands-on Experience</p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-chalkboard-teacher"></i>
            <p>Engage Directly with Expert Instructors</p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-tasks"></i>
            <p>Evaluate Your Skills and Identify Growth Areas</p>
          </div>
          <div className="benefit-card">
            <i className="fas fa-rocket"></i>
            <p>Build Confidence for Your Career Success</p>
          </div>
        </div>

        {/* Button Section */}
        <div className="subscribe-img-section">
       
        </div>
      </div>
    </div>
  );
};

export default SubscribeDemo;
