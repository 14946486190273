import React, { useState } from "react";
import { FaProjectDiagram, FaGraduationCap, FaBriefcase, FaHandsHelping, FaChalkboardTeacher, FaNetworkWired } from "react-icons/fa";
import "../Css/JobPreparation.css";

const JobPreparation = () => {
  const [activeTab, setActiveTab] = useState("CourseCompletion");

  const renderContent = () => {
    switch (activeTab) {
      case "CourseCompletion":
        return (
          <table className="job-tab-content">
            <thead>
              <tr>
                <th colSpan="2" className="job-table-header">Achieving Course Milestones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="job-table-row-header"><FaGraduationCap className="job-icon" /> Hands-On Training with Internships</td>
                <td>
                  Participate in our internship opportunities to bridge the gap between theory and practice. Develop workplace-ready skills while working on tasks that mirror real-world scenarios.
                </td>
              </tr>
              <tr>
                <td className="job-table-row-header"><FaProjectDiagram className="job-icon" /> Industry-Driven Project Experience</td>
                <td>
                  Engage in projects designed to meet market standards. Strengthen your portfolio with practical implementations that demonstrate your expertise and innovation.
                </td>
              </tr>
              <tr>
                <td className="job-table-row-header"><FaBriefcase className="job-icon" /> Resume Writing Excellence</td>
                <td>
                  Create an impactful resume that highlights your strengths and achievements effectively. Impress employers with a professional and tailored profile.
                </td>
              </tr>
            </tbody>
          </table>
        );
      case "InterviewPreparation":
        return (
          <table className="job-tab-content">
            <thead>
              <tr>
                <th colSpan="2" className="job-table-header">Interview Readiness Strategies</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="job-table-row-header"><FaChalkboardTeacher className="job-icon" /> Core Competency Development</td>
                <td>
                  Sharpen your technical and analytical abilities with targeted practice sessions. Gain the confidence to tackle interview challenges with ease.
                </td>
              </tr>
              <tr>
                <td className="job-table-row-header"><FaHandsHelping className="job-icon" /> Mock Interview Simulations</td>
                <td>
                  Participate in realistic interview exercises that mimic actual hiring processes. Get constructive feedback to refine your performance.
                </td>
              </tr>
              <tr>
                <td className="job-table-row-header"><FaNetworkWired className="job-icon" /> Dynamic Group Discussions</td>
                <td>
                  Enhance your communication and collaboration skills through interactive group sessions. Learn how to articulate your ideas effectively in a team setting.
                </td>
              </tr>
            </tbody>
          </table>
        );
      case "Placement":
        return (
          <table className="job-tab-content">
            <thead>
              <tr>
                <th colSpan="2" className="job-table-header">Securing Your Dream Job</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="job-table-row-header"><FaBriefcase className="job-icon" /> Ongoing Career Support</td>
                <td>
                  Receive dedicated assistance throughout your job search until you secure a role with a leading organization. Benefit from personalized guidance at every step.
                </td>
              </tr>
              <tr>
                <td className="job-table-row-header"><FaHandsHelping className="job-icon" /> Exclusive Referral Opportunities</td>
                <td>
                  Leverage our extensive network for access to exclusive job openings. Open doors to exciting career paths through our referral program.
                </td>
              </tr>
            </tbody>
          </table>
        );
      default:
        return null;
    } 
  };

  return (
    <div className="job-preparation-container">
      <h2 className="job-main-heading">Your Journey to Professional Success</h2>
      <div className="job-tabs">
        <button
          className={`job-tab-button ${activeTab === "CourseCompletion" ? "job-tab-active" : ""}`}
          onClick={() => setActiveTab("CourseCompletion")}
        >
          Skill Development & Certification
        </button>
        <button
          className={`job-tab-button ${activeTab === "InterviewPreparation" ? "job-tab-active" : ""}`}
          onClick={() => setActiveTab("InterviewPreparation")}
        >
          Excelling in Interviews
        </button>
        <button
          className={`job-tab-button ${activeTab === "Placement" ? "job-tab-active" : ""}`}
          onClick={() => setActiveTab("Placement")}
        >
          Placement Support Program
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default JobPreparation;
