import React from "react";
import Marquee from "react-fast-marquee";
import "../Css/AlumniMarquee.css"; 

const companyData = [
    {
        name: "Google",
        logo: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg",
    },
    {
        name: "Facebook",
        logo: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg", 
    },
    {
        name: "Amazon",
        logo: "https://w7.pngwing.com/pngs/1012/770/png-transparent-amazon-logo-amazon-com-amazon-video-logo-company-brand-amazon-logo-miscellaneous-wish-text.png", 
    },
    {
        name: "Microsoft",
        logo: "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg", // Microsoft logo
    },
    {
        name: "Salesforce",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1280px-Salesforce.com_logo.svg.png", 
    },
    {
        name: "Apple",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Apple_logo_white.svg/758px-Apple_logo_white.svg.png?20220821122232", 
    },
    {
        name: "IBM",
        logo: "https://upload.wikimedia.org/wikipedia/commons/5/51/IBM_logo.svg", 
    },
    {
        name: "Adobe",
        logo: "https://logos-world.net/wp-content/uploads/2020/08/Adobe-Logo-2023.png", 
    },
    {
        name: "Netflix",
        logo: "https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg",
    },
    {
        name: "Uber",
        logo: "https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoid2VhcmVcL2ZpbGVcLzhGbTh4cU5SZGZUVjUxYVh3bnEyLnN2ZyJ9:weare:F1cOF9Bps96cMy7r9Y2d7affBYsDeiDoIHfqZrbcxAw?width=1200&height=417", 
    },
    {
        name: "Twitter",
        logo: "https://seeklogo.com/images/T/twitter-icon-square-logo-108D17D373-seeklogo.com.png",
    },
    {
        name: "LinkedIn",
        logo: "https://www.freeiconspng.com/thumbs/linkedin-logo-png/linkedin-logo-3.png", 
    },
    {
        name: "Zoom",
        logo: "https://i.pinimg.com/originals/bf/54/a7/bf54a70f234c9bfa60c6040618ac9079.png", 
    },
    {
        name: "PayPal",
        logo: "https://pngimg.com/uploads/paypal/paypal_PNG22.png", 
    },
    {
        name: "Flipkart",
        logo: "https://cdn.iconscout.com/icon/free/png-256/free-flipkart-logo-icon-download-in-svg-png-gif-file-formats--online-shopping-brand-logos-pack-icons-226594.png?f=webp", 
    },
];

const AlumniMarquee = () => {
    return (
        <div className="alumni-marquee-section">
            <h1>Our Alumni Working here</h1>
            <Marquee gradient={true} speed={50} gradientWidth={40} >
                {companyData.map((company, index) => (
                    <div key={index} className="company-item">
                        <img
                            src={company.logo}
                            alt={company.name}
                            className="company-icon"
                        />
                    </div>
                ))}
            </Marquee>
        </div>
    );
};

export default AlumniMarquee;
